/* Cubic */
.col-xxs .col-xxs-1,
.col-xxs .col-xxs-2,
.col-xxs .col-xxs-3,
.col-xxs .col-xxs-4,
.col-xxs .col-xxs-5,
.col-xxs .col-xxs-6,
.col-xxs .col-xxs-7,
.col-xxs .col-xxs-8,
.col-xxs .col-xxs-9,
.col-xxs .col-xxs-10,
.col-xxs .col-xxs-11,
.col-xxs .col-xxs-12 {
  float: left;
}
.col-xxs .col-xxs-12 {
  width: 100%;
}
.col-xxs .col-xxs-11 {
  width: 91.66666667%;
}
.col-xxs .col-xxs-10 {
  width: 83.33333333%;
}
.col-xxs .col-xxs-9 {
  width: 75%;
}
.col-xxs .col-xxs-8 {
  width: 66.66666667%;
}
.col-xxs .col-xxs-7 {
  width: 58.33333333%;
}
.col-xxs .col-xxs-6 {
  width: 50%;
}
.col-xxs .col-xxs-5 {
  width: 41.66666667%;
}
.col-xxs .col-xxs-4 {
  width: 33.33333333%;
}
.col-xxs .col-xxs-3 {
  width: 25%;
}
.col-xxs .col-xxs-2 {
  width: 16.66666667%;
}
.col-xxs .col-xxs-1 {
  width: 8.33333333%;
}
.col-xxs .col-xxs-offset-12 {
  margin-left: 100%;
}
.col-xxs .col-xxs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xxs .col-xxs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xxs .col-xxs-offset-9 {
  margin-left: 75%;
}
.col-xxs .col-xxs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xxs .col-xxs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xxs .col-xxs-offset-6 {
  margin-left: 50%;
}
.col-xxs .col-xxs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xxs .col-xxs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xxs .col-xxs-offset-3 {
  margin-left: 25%;
}
.col-xxs .col-xxs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xxs .col-xxs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xxs .col-xxs-offset-0 {
  margin-left: 0;
}
.col-xxs .hidden-xxs {
  display: none !important;
}
.col-xl .col-xl-1,
.col-xl .col-xl-2,
.col-xl .col-xl-3,
.col-xl .col-xl-4,
.col-xl .col-xl-5,
.col-xl .col-xl-6,
.col-xl .col-xl-7,
.col-xl .col-xl-8,
.col-xl .col-xl-9,
.col-xl .col-xl-10,
.col-xl .col-xl-11,
.col-xl .col-xl-12 {
  float: left;
}
.col-xl .col-xl-12 {
  width: 100%;
}
.col-xl .col-xl-11 {
  width: 91.66666667%;
}
.col-xl .col-xl-10 {
  width: 83.33333333%;
}
.col-xl .col-xl-9 {
  width: 75%;
}
.col-xl .col-xl-8 {
  width: 66.66666667%;
}
.col-xl .col-xl-7 {
  width: 58.33333333%;
}
.col-xl .col-xl-6 {
  width: 50%;
}
.col-xl .col-xl-5 {
  width: 41.66666667%;
}
.col-xl .col-xl-4 {
  width: 33.33333333%;
}
.col-xl .col-xl-3 {
  width: 25%;
}
.col-xl .col-xl-2 {
  width: 16.66666667%;
}
.col-xl .col-xl-1 {
  width: 8.33333333%;
}
.col-xl .col-xl-offset-12 {
  margin-left: 100%;
}
.col-xl .col-xl-offset-11 {
  margin-left: 91.66666667%;
}
.col-xl .col-xl-offset-10 {
  margin-left: 83.33333333%;
}
.col-xl .col-xl-offset-9 {
  margin-left: 75%;
}
.col-xl .col-xl-offset-8 {
  margin-left: 66.66666667%;
}
.col-xl .col-xl-offset-7 {
  margin-left: 58.33333333%;
}
.col-xl .col-xl-offset-6 {
  margin-left: 50%;
}
.col-xl .col-xl-offset-5 {
  margin-left: 41.66666667%;
}
.col-xl .col-xl-offset-4 {
  margin-left: 33.33333333%;
}
.col-xl .col-xl-offset-3 {
  margin-left: 25%;
}
.col-xl .col-xl-offset-2 {
  margin-left: 16.66666667%;
}
.col-xl .col-xl-offset-1 {
  margin-left: 8.33333333%;
}
.col-xl .col-xl-offset-0 {
  margin-left: 0;
}
.col-xl .col-xl-push-1 {
  left: 8.33333333%;
}
.col-xl .col-xl-push-2 {
  left: 16.66666667%;
}
.col-xl .col-xl-push-3 {
  left: 25%;
}
.col-xl .col-xl-push-4 {
  left: 33.33333333%;
}
.col-xl .col-xl-push-5 {
  left: 41.66666667%;
}
.col-xl .col-xl-push-6 {
  left: 50%;
}
.col-xl .col-xl-push-7 {
  left: 58.33333333%;
}
.col-xl .col-xl-push-8 {
  left: 66.66666667%;
}
.col-xl .col-xl-push-9 {
  left: 75%;
}
.col-xl .col-xl-push-10 {
  left: 83.33333333%;
}
.col-xl .col-xl-push-11 {
  left: 91.66666667%;
}
.col-xl .col-xl-pull-1 {
  right: 8.33333333%;
}
.col-xl .col-xl-pull-2 {
  right: 16.66666667%;
}
.col-xl .col-xl-pull-3 {
  right: 25%;
}
.col-xl .col-xl-pull-4 {
  right: 33.33333333%;
}
.col-xl .col-xl-pull-5 {
  right: 41.66666667%;
}
.col-xl .col-xl-pull-6 {
  right: 50%;
}
.col-xl .col-xl-pull-7 {
  right: 58.33333333%;
}
.col-xl .col-xl-pull-8 {
  right: 66.66666667%;
}
.col-xl .col-xl-pull-9 {
  right: 75%;
}
.col-xl .col-xl-pull-10 {
  right: 83.33333333%;
}
.col-xl .col-xl-pull-11 {
  right: 91.66666667%;
}
.col-xl .hidden-xl {
  display: none !important;
}
*:focus,
input[type=file]:focus,
input[type=checkbox]:focus,
input[type=radio]:focus {
  outline: 0;
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"],
.row.no-gutters > [class*=" col-"] {
  padding-right: 0;
  padding-left: 0;
}
.row-xs-eq-height {
  display: flex;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.alert-env {
  background: #000;
  border-radius: 0;
  margin-bottom: 0;
  padding: 5px 15px;
  font-size: 14px;
  font-weight: normal;
  color: #fff;
  text-align: center;
}
@media (min-width: 768px) {
  .row-sm-eq-height {
    display: flex;
  }
}
@media (min-width: 992px) {
  .row-md-eq-height {
    display: flex;
  }
}
@media (min-width: 1200px) {
  .row-lg-eq-height {
    display: flex;
  }
}
@media (min-width: 1400px) {
  .row-xl-eq-height {
    display: flex;
  }
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-11 {
    width: 91.66666667%;
  }
  .col-xl-10 {
    width: 83.33333333%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-8 {
    width: 66.66666667%;
  }
  .col-xl-7 {
    width: 58.33333333%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-5 {
    width: 41.66666667%;
  }
  .col-xl-4 {
    width: 33.33333333%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-2 {
    width: 16.66666667%;
  }
  .col-xl-1 {
    width: 8.33333333%;
  }
  .col-xl-offset-12 {
    margin-left: 100%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xl-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xl-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xl-offset-0 {
    margin-left: 0;
  }
  .col-xl-push-1 {
    left: 8.33333333%;
  }
  .col-xl-push-2 {
    left: 16.66666667%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.33333333%;
  }
  .col-xl-push-5 {
    left: 41.66666667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.33333333%;
  }
  .col-xl-push-8 {
    left: 66.66666667%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.33333333%;
  }
  .col-xl-push-11 {
    left: 91.66666667%;
  }
  .col-xl-pull-1 {
    right: 8.33333333%;
  }
  .col-xl-pull-2 {
    right: 16.66666667%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.33333333%;
  }
  .col-xl-pull-5 {
    right: 41.66666667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.33333333%;
  }
  .col-xl-pull-8 {
    right: 66.66666667%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.33333333%;
  }
  .col-xl-pull-11 {
    right: 91.66666667%;
  }
  .hidden-xl {
    display: none !important;
  }
}
@media (max-width: 480px) {
  .col-xxs-1,
  .col-xxs-2,
  .col-xxs-3,
  .col-xxs-4,
  .col-xxs-5,
  .col-xxs-6,
  .col-xxs-7,
  .col-xxs-8,
  .col-xxs-9,
  .col-xxs-10,
  .col-xxs-11,
  .col-xxs-12 {
    float: left;
  }
  .col-xxs-12 {
    width: 100%;
  }
  .col-xxs-11 {
    width: 91.66666667%;
  }
  .col-xxs-10 {
    width: 83.33333333%;
  }
  .col-xxs-9 {
    width: 75%;
  }
  .col-xxs-8 {
    width: 66.66666667%;
  }
  .col-xxs-7 {
    width: 58.33333333%;
  }
  .col-xxs-6 {
    width: 50%;
  }
  .col-xxs-5 {
    width: 41.66666667%;
  }
  .col-xxs-4 {
    width: 33.33333333%;
  }
  .col-xxs-3 {
    width: 25%;
  }
  .col-xxs-2 {
    width: 16.66666667%;
  }
  .col-xxs-1 {
    width: 8.33333333%;
  }
  .col-xxs-offset-12 {
    margin-left: 100%;
  }
  .col-xxs-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xxs-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xxs-offset-9 {
    margin-left: 75%;
  }
  .col-xxs-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xxs-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xxs-offset-6 {
    margin-left: 50%;
  }
  .col-xxs-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xxs-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xxs-offset-3 {
    margin-left: 25%;
  }
  .col-xxs-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xxs-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xxs-offset-0 {
    margin-left: 0;
  }
  .hidden-xxs {
    display: none !important;
  }
}
.creme {
  background-color: #f8f4ec;
}
.creme .title span {
  background: #f8f4ec;
}
.white {
  color: #fff;
}
.black {
  color: #253135;
}
.red {
  color: #fc3e41;
}
.green {
  color: #24c196;
}
html {
  font-size: 9px;
}
body {
  font-size: 2.1rem;
  font-weight: 300;
  font-family: "adobe-text-pro", serif;
}
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  font-family: "myriad-pro-condensed", sans-serif;
  color: #253135;
  font-weight: 700;
}
h1,
.h1 {
  font-size: 6rem;
  position: relative;
  color: #fc3e41;
}
h2,
.h2 {
  position: relative;
  font-size: 3.4rem;
  color: #686d73;
}
h2.title span,
.h2.title span {
  position: relative;
  color: #fc3e41;
  padding: 0 1.5rem;
  background-color: #fff;
  z-index: 1;
}
h2.title:after,
.h2.title:after {
  content: '';
  position: absolute;
  left: 0;
  top: 80%;
  right: 0;
  height: 2px;
  background-color: rgba(104, 109, 115, 0.55);
}
h2 span,
.h2 span {
  color: #253135;
}
h3,
.h3 {
  font-size: 3.2rem;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 3rem;
}
h3 span,
.h3 span {
  font-size: 3.2rem;
  color: #fff;
}
img {
  max-width: 100%;
  height: auto;
}
.img-full-width {
  margin-left: -15px;
  margin-right: -15px;
}
.img-border {
  border: 1px solid rgba(2, 8, 24, 0.1);
}
.img-object-fit {
  height: 100%;
  width: 100%;
}
a {
  color: #fc3e41;
}
a:hover,
a:focus,
a:active,
a.active {
  color: #fc3e41;
  text-decoration: underline;
  outline: none;
}
p.small {
  font-size: 75%;
}
.lead {
  font-weight: 700;
  margin-top: 20px;
}
.date,
.time {
  font-family: "myriad-pro-condensed", sans-serif;
}
.btn {
  position: relative;
  font-family: "myriad-pro-condensed", sans-serif;
  font-size: 2.1rem;
  font-weight: 700;
  border-radius: 35px;
}
.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover,
.btn:hover,
.btn:focus,
.btn:active,
.btn.active {
  box-shadow: none;
  outline: 0;
}
.btn:not(.dropdown-toggle) {
  text-transform: uppercase;
}
.input-group-btn:last-child > .btn {
  font-size: 2.4rem;
  color: #686d73;
  line-height: inherit;
  padding: 0 0 0 .35rem;
  border: 0;
  background: transparent;
  transition: color 200ms;
}
.input-group-btn:last-child > .btn:hover {
  color: #24c196;
  background: transparent;
}
.input-group-btn:last-child > .btn .wb-search {
  font-size: 1.8rem;
  bottom: 2px;
}
.input-group-btn:last-child > .btn .wb-circle {
  font-size: 5.2rem;
  bottom: 0;
}
.btn-default,
.btn-primary {
  border: 2px solid #253135;
  background: transparent;
  color: #253135;
  padding: 0 45px 0 30px;
  height: 5rem;
  line-height: 2.1;
  transition: border 200ms;
}
.wb-events-icons-ready .btn-default:after,
.wb-events-icons-ready .btn-primary:after {
  content: '\f01a';
  font-family: "NiceToMeat";
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  transition: right 200ms;
  color: #24c196;
}
.btn-default.active.focus,
.btn-primary.active.focus,
.btn-default.active:focus,
.btn-primary.active:focus,
.btn-default.active:hover,
.btn-primary.active:hover,
.btn-default:active.focus,
.btn-primary:active.focus,
.btn-default:active:focus,
.btn-primary:active:focus,
.btn-default:active:hover,
.btn-primary:active:hover,
.btn-default:hover,
.btn-primary:hover,
.btn-default:focus,
.btn-primary:focus,
.btn-default:active,
.btn-primary:active,
.btn-default.active,
.btn-primary.active {
  color: #253135;
  border: 2px solid #24c196;
  background: transparent;
  text-decoration: none;
}
.btn-default.active.focus:after,
.btn-primary.active.focus:after,
.btn-default.active:focus:after,
.btn-primary.active:focus:after,
.btn-default.active:hover:after,
.btn-primary.active:hover:after,
.btn-default:active.focus:after,
.btn-primary:active.focus:after,
.btn-default:active:focus:after,
.btn-primary:active:focus:after,
.btn-default:active:hover:after,
.btn-primary:active:hover:after,
.btn-default:hover:after,
.btn-primary:hover:after,
.btn-default:focus:after,
.btn-primary:focus:after,
.btn-default:active:after,
.btn-primary:active:after,
.btn-default.active:after,
.btn-primary.active:after {
  right: 15px;
}
.btn-default.btn-white:after,
.btn-primary.btn-white:after {
  color: #fff;
}
.btn-default.btn-white.active.focus,
.btn-primary.btn-white.active.focus,
.btn-default.btn-white.active:focus,
.btn-primary.btn-white.active:focus,
.btn-default.btn-white.active:hover,
.btn-primary.btn-white.active:hover,
.btn-default.btn-white:active.focus,
.btn-primary.btn-white:active.focus,
.btn-default.btn-white:active:focus,
.btn-primary.btn-white:active:focus,
.btn-default.btn-white:active:hover,
.btn-primary.btn-white:active:hover,
.btn-default.btn-white:hover,
.btn-primary.btn-white:hover,
.btn-default.btn-white:focus,
.btn-primary.btn-white:focus,
.btn-default.btn-white:active,
.btn-primary.btn-white:active,
.btn-default.btn-white.active,
.btn-primary.btn-white.active {
  border-color: #fff;
}
.btn-default.btn-classic,
.btn-primary.btn-classic,
.btn-default.btn-select,
.btn-primary.btn-select,
.btn-default.btn-gold,
.btn-primary.btn-gold {
  padding: 20px;
}
.btn-default.btn-classic:after,
.btn-primary.btn-classic:after,
.btn-default.btn-select:after,
.btn-primary.btn-select:after,
.btn-default.btn-gold:after,
.btn-primary.btn-gold:after {
  content: '';
  background-image: url('/theme/img/label-classic.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 36px;
  height: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}
.btn-default.btn-select:after,
.btn-primary.btn-select:after {
  background-image: url('/theme/img/label-select.png');
}
.btn-default.btn-gold:after,
.btn-primary.btn-gold:after {
  background-image: url('/theme/img/label-gold.png');
}
.btn-primary {
  color: #fff;
  background-color: #24c196;
  border: 2px solid #24c196;
  transition: background 200ms, opacity 200ms;
}
.wb-events-icons-ready .btn-primary:after {
  opacity: 0;
  right: 0;
  transition: right 200ms, opacity 200ms;
  color: #253135;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active {
  color: #fff;
  background: #24c196;
  border: 2px solid #24c196;
}
.btn-primary.active.focus:after,
.btn-primary.active:focus:after,
.btn-primary.active:hover:after,
.btn-primary:active.focus:after,
.btn-primary:active:focus:after,
.btn-primary:active:hover:after,
.btn-primary:hover:after,
.btn-primary:focus:after,
.btn-primary:active:after,
.btn-primary.active:after {
  opacity: 1;
  right: 10px;
}
.btn-link,
.btn-prev {
  font-size: 2.1rem;
  font-weight: 700;
  color: #fc3e41;
  text-transform: uppercase;
  padding: 1rem 1.5rem 1rem 0;
  transition: color 200ms;
}
.wb-events-icons-ready .btn-link:after,
.wb-events-icons-ready .btn-prev:after {
  content: '\f01a';
  position: absolute;
  top: 52%;
  right: -10px;
  font-size: 2.6rem;
  font-family: "NiceToMeat";
  color: #fc3e41;
  transform: translateY(-50%);
  transition: right 200ms, color 200ms;
}
.btn-link:hover,
.btn-prev:hover,
.btn-link:focus,
.btn-prev:focus,
.btn-link:active,
.btn-prev:active,
.btn-link:active:focus,
.btn-prev:active:focus {
  color: #24c196;
  text-decoration: none;
}
.wb-events-icons-ready .btn-link:hover:after,
.wb-events-icons-ready .btn-prev:hover:after,
.wb-events-icons-ready .btn-link:focus:after,
.wb-events-icons-ready .btn-prev:focus:after,
.wb-events-icons-ready .btn-link:active:after,
.wb-events-icons-ready .btn-prev:active:after,
.wb-events-icons-ready .btn-link:active:focus:after,
.wb-events-icons-ready .btn-prev:active:focus:after {
  right: -15px;
  color: #24c196;
}
.btn-prev {
  padding: 1rem 0 1rem 1.5rem;
}
.wb-events-icons-ready .btn-prev:after {
  content: '\f019';
  left: -10px;
  right: inherit;
  transition: left 200ms, color 200ms;
}
.wb-events-icons-ready .btn-prev:hover:after,
.wb-events-icons-ready .btn-prev:focus:after,
.wb-events-icons-ready .btn-prev:active:after,
.wb-events-icons-ready .btn-prev:active:focus:after {
  left: -15px;
  right: inherit;
}
.btn-group-sm > .btn,
.btn-sm {
  font-size: 1.2rem;
}
[data-href] {
  cursor: pointer;
}
.open > .dropdown-toggle.btn-default:hover,
.open > .dropdown-toggle.btn-default:focus,
.open > .dropdown-toggle.btn-default:active,
.open > .dropdown-toggle.btn-default:active:focus {
  color: #253135;
  border: 2px solid #24c196;
  background: transparent;
  text-decoration: none;
}
.modal-backdrop {
  background-color: #fff;
}
.modal-backdrop.in {
  opacity: .8;
}
.modal-content {
  box-shadow: none;
  border: 2px solid #c3c4c6;
}
.modal-header,
.modal-body {
  padding: 25px;
}
.modal-header {
  padding-bottom: 0;
  border: none;
}
.modal-header .modal-title {
  color: #fc3e41;
  font-size: 2.8rem;
  line-height: 1;
}
.slider-nav .slick-slide picture {
  transition: opacity 300ms ease-in-out;
}
.slider-nav .slick-slide picture:last-child {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}
.slider-nav .slick-slide .when-active {
  opacity: 0;
}
.slider-nav .slick-slide.slick-current .when-active {
  opacity: 1;
}
.slick-nav-wrap li .wb {
  color: #686d73;
  padding: 0 .5rem;
  opacity: .5;
  transition: opacity 200ms;
  cursor: pointer;
}
.slick-nav-wrap li .wb:hover,
.slick-nav-wrap li .wb:focus,
.slick-nav-wrap li .wb:active,
.slick-nav-wrap li .wb:active:focus {
  opacity: 1;
}
.slides {
  opacity: 0;
}
[type="radio"],
[type="checkbox"] {
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}
input[type=radio] + span:before,
input[type=checkbox] + span:before {
  content: '';
  display: inline-block;
  border-radius: 50%;
  border: 2px solid #253135;
  transition: 0.5s ease all;
  height: 15px;
  width: 15px;
  margin-left: -2rem;
  margin-right: 1rem;
  cursor: pointer;
}
[type="radio"]:checked + span:before,
[type="checkbox"]:checked + span:before {
  background: #24c196;
}
input[type=checkbox] + span:before {
  border-radius: 0;
  border: 2px solid #253135;
}
form span {
  color: #fc3e41;
}
.form-control {
  font-family: "adobe-text-pro", serif;
  font-size: 2.1rem;
  padding: 6px 20px 7px;
  border: none;
  border-radius: 35px;
  background: #fff;
  height: 5rem;
  box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  color: #686d73;
  font-size: 1.8rem;
}
.form-control:focus {
  box-shadow: none;
  outline: 0;
  border-color: #24c196;
}
.input-group .form-control:first-child {
  border-radius: 28px;
}
.form-product .form-control,
.form-job .form-control {
  border: 2px solid #686d73;
}
.form-product .form-control::-webkit-input-placeholder,
.form-job .form-control::-webkit-input-placeholder {
  color: #686d73;
}
.search .form-control {
  border: 2px solid rgba(104, 109, 115, 0.5);
}
.has-error .form-group,
.has-error .form-control {
  border-color: #fc3e41;
  background: #fff;
}
.has-error .help-block {
  color: #fc3e41;
  font-size: 14px;
}
textarea.form-control {
  height: 180px;
  border-radius: 20px;
}
.form-contact {
  position: relative;
  margin-top: 9rem;
  margin-bottom: 5rem;
}
.form-contact:before {
  content: '';
  background: #fc3e41;
  position: absolute;
  top: -45px;
  left: 0;
  width: 40px;
  height: 4px;
  border-radius: 3px;
}
.form-contact.form-horizontal .control-label {
  text-align: left;
  font-weight: 400;
}
.form-contact hr {
  border-color: #fff;
}
.form-contact ul li {
  display: inline-block;
}
.dropzone {
  position: relative;
  text-align: center;
  padding: 3.5rem 2rem;
  margin: 1rem 0 2rem;
  background: transparent;
  border: 3px dashed rgba(104, 109, 115, 0.25);
  border-radius: 25px;
  bottom: 100%;
  left: 0;
  right: 0;
}
.dropzone .dz-message {
  margin: 0;
}
.dropzone .dz-preview .dz-progress {
  top: auto;
  bottom: 10%;
}
.dropzone .dz-preview .dz-progress .progress-bar {
  background-color: black;
}
.dropzone p {
  margin-bottom: 10px !important;
}
.dropzone .btn-link {
  padding: 0 1.5rem 0;
}
.dropzone.has-error {
  border: 3px dashed #fc3e41;
}
.dz-remove {
  position: absolute;
  z-index: 21;
  top: -3px;
  right: -3px;
}
.alert {
  color: #fff;
  text-align: center;
  border-color: #f8f4ec;
}
.alert-info {
  background: #24c196;
  border: transparent;
}
.alert-danger {
  background: #fc3e41;
}
.alert-env {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: #253135;
  padding: 5px 15px;
  border-color: #253135;
}
.header-content .dropdown-toggle {
  padding: 5px 10px;
  color: #686d73;
  line-height: inherit;
}
.header-content .dropdown-toggle i {
  color: #24c196;
  font-size: 1.3rem;
  transform: translateY(-2px);
}
.header-content .dropdown-toggle span {
  margin-right: .5rem;
}
.btn-group.open .dropdown-toggle {
  background: #fff;
  box-shadow: none;
}
.dropdown-menu {
  font-family: "myriad-pro-condensed", sans-serif;
  font-size: 18px;
  border: 2px solid #253135;
  border-radius: 20px;
  padding: 0;
  overflow: hidden;
  top: 60px;
  left: 10px;
  min-width: 120px;
}
.dropdown-menu li a {
  position: relative;
  padding-left: 4rem;
  background: #fff;
}
.dropdown-menu li a:before {
  content: '\f01a';
  font-family: "NiceToMeat";
  color: #253135;
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  transition: color 200ms;
}
.dropdown-menu li a:hover {
  background: #fff;
}
.dropdown-menu li a:hover:before {
  color: #24c196;
}
section {
  position: relative;
  padding: 5rem 0;
}
.wb-events-icons-ready section:not(.page-intro):not(.standaard):not(.agenda):not(.overlay-choose):not(.overlay-popup):not(.section-first):not(.form):after {
  font-family: "NiceToMeat";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '\f020';
  color: #686d73;
  text-align: center;
  line-height: 3;
  background: #fff;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  z-index: 4;
}
section h2 {
  margin: 0 0 3rem;
}
section.standaard h2 {
  margin: 3rem 0 1rem;
}
.section-header {
  position: relative;
  margin-bottom: 2rem;
}
.section-header .btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
.section-header .btn.btn-prev {
  left: 0;
  right: inherit;
}
.section-first {
  padding: 2rem 0 10rem;
}
.parallax-wrap {
  width: 100%;
  height: 170px;
  position: relative;
  overflow: hidden;
}
.parallax-wrap img {
  width: 100vmax;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  pointer-events: none;
}
main {
  margin-top: 60px;
}
header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 60px;
  background: #fff;
  z-index: 5;
  border-bottom: 2px solid rgba(104, 109, 115, 0.2);
}
.logo {
  display: inline-block;
  padding-top: 1.2rem;
}
.header-content {
  display: flex;
  justify-content: flex-end;
}
.header-content .btn-default:after {
  content: none;
}
.header-content .form-control {
  border: 2px solid #686d73;
}
.header-content > div:not(:last-child) {
  padding: 7px 10px 0;
}
.header-content > div:last-child {
  margin-right: -15px;
}
.menu-trigger {
  background: #fc3e41;
  padding: 12px;
  margin-right: -15px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
}
.menu-trigger:hover,
.menu-trigger:focus,
.menu-trigger:active,
.menu-trigger:active:focus {
  background: #fb0c10;
}
.menu-trigger .hamburger {
  width: 34px;
  height: 34px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.menu-trigger .hamburger span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: #fff;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.menu-trigger .hamburger span:nth-child(1) {
  top: 0;
}
.menu-trigger .hamburger span:nth-child(2),
.menu-trigger .hamburger span:nth-child(3) {
  top: 12px;
}
.menu-trigger .hamburger span:nth-child(4) {
  top: 24px;
}
.menu-trigger.open .hamburger span:nth-child(1) {
  top: 12px;
  width: 0;
  left: 50%;
}
.menu-trigger.open .hamburger span:nth-child(2) {
  transform: rotate(45deg);
}
.menu-trigger.open .hamburger span:nth-child(3) {
  transform: rotate(-45deg);
}
.menu-trigger.open .hamburger span:nth-child(4) {
  top: 12px;
  width: 0;
  left: 50%;
}
.switch {
  color: #fff;
  font-family: "myriad-pro-condensed", sans-serif;
  font-weight: 700;
  padding: 10px 0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
.switch form {
  margin: 0;
}
.switch-toggle {
  display: inline-block;
  background: #1b2326;
  border-radius: 35px;
  margin: 0 15px;
  padding: 5px;
  box-shadow: inset 0 0 10px #020818;
}
.switch-toggle input {
  position: absolute;
}
.switch-toggle input:checked + label {
  color: #fff;
  background-color: #1fc470;
}
.switch-toggle label {
  display: inline-block;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  border-radius: 35px;
  padding: 2px 14px;
  margin: 0;
  transition: all 0.1s ease-in-out;
}
.switch-toggle label:hover {
  color: #fff;
  cursor: pointer;
}
.menu-wrapper {
  display: none;
  position: fixed;
  z-index: 6;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: #253135;
  overflow: auto;
}
.menu-wrapper:after {
  content: '';
  background: url('/theme/img/beeldmerk.png') center no-repeat;
  background-size: contain;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.menu-wrapper .main-menu {
  position: relative;
  padding: 0 0 6rem 0;
  margin: 0;
  overflow: auto;
  height: calc(100vh - 60px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-wrapper .main-menu:before {
  content: '';
  background: #fc3e41;
  position: absolute;
  top: -10rem;
  left: 50%;
  width: 40px;
  height: 4px;
  border-radius: 3px;
  transform: translateX(-50%);
}
.menu-wrapper .main-menu li {
  transition: background 200ms;
}
.menu-wrapper .main-menu li:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.menu-wrapper .main-menu li a {
  display: block;
  font-family: "myriad-pro-condensed", sans-serif;
  font-size: 2.1rem;
  font-weight: 700;
  letter-spacing: 5px;
  color: #fff;
  padding: 1.8rem 0;
  text-transform: uppercase;
  cursor: pointer;
}
.menu-wrapper .main-menu li a:hover,
.menu-wrapper .main-menu li a:focus,
.menu-wrapper .main-menu li a:active,
.menu-wrapper .main-menu li a:active:focus,
.menu-wrapper .main-menu li a.active {
  color: #fc3e41;
  text-decoration: none;
}
.menu-wrapper .main-menu li.active {
  color: #fc3e41;
}
.menu-wrapper .main-menu li ul {
  transform: translateY(-25px);
  padding: 5px 0 0 0;
}
.menu-wrapper .main-menu li ul li {
  display: inline-block;
}
.menu-wrapper .main-menu li ul li:not(:last-child) {
  border-bottom: none;
}
.menu-wrapper .main-menu li ul li:not(:last-child) {
  border-right: 2px solid #fc3e41;
}
.menu-wrapper .main-menu li ul li a {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.6rem;
  padding: 0 .8rem;
  line-height: 1;
  transition: color 200ms;
  letter-spacing: 2px;
}
.menu-wrapper .main-menu li ul li a:hover,
.menu-wrapper .main-menu li ul li a:focus,
.menu-wrapper .main-menu li ul li a:active,
.menu-wrapper .main-menu li ul li a:active:focus,
.menu-wrapper .main-menu li ul li a.active {
  background: transparent;
  color: #fc3e41;
  text-decoration: none;
}
.overlay-choose {
  display: none;
  background: rgba(255, 255, 255, 0.95);
  position: fixed;
  z-index: 6;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  padding: 2rem 0 0 0;
}
.overlay-popup {
  display: none;
  position: fixed;
  right: 10px;
  bottom: 0;
  min-height: 30vh;
  width: 300px;
  padding: 15px 25px;
  border: 4px solid #24c196;
  border-bottom: 0;
  background: #fff;
  z-index: 6;
}
.overlay-popup img {
  border: 3px solid rgba(104, 109, 115, 0.3);
}
.overlay-popup .btn-close {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  background: transparent;
}
.overlay-popup .btn-close .wb {
  color: #fc3e41;
  font-size: 2.8rem;
}
.overlay-popup small {
  display: block;
  font-size: 1.4rem;
  font-family: "myriad-pro-condensed", sans-serif;
  font-weight: 700;
  color: rgba(37, 49, 53, 0.5);
  text-transform: uppercase;
}
.overlay-popup h2 {
  font-size: 2.1rem;
  color: #253135;
  margin-bottom: 1.5rem;
}
.overlay-popup p {
  font-size: 1.8rem;
  line-height: 1.9rem;
  font-family: "myriad-pro-condensed", sans-serif;
  margin-bottom: 1.5rem;
}
.page-intro {
  color: #fff;
  padding: 0;
}
.page-intro .container-fluid {
  margin: 0;
  padding: 0;
}
.page-intro h1 {
  color: #fff;
  margin: 2rem 0;
}
.page-intro .intro-text {
  position: relative;
  background: url('/theme/img/intro-image.png') #686d73 center no-repeat;
  background-size: contain;
  height: 400px;
  z-index: 1;
  background-color: rgba(104, 109, 115, 0.55);
}
.page-intro .intro-text:after {
  content: '';
  background: rgba(37, 49, 53, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.page-intro .intro-text > div {
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  transform: translateY(-50%);
  z-index: 2;
}
.page-intro .intro-text > div:before {
  content: '';
  background: #fc3e41;
  position: absolute;
  top: 0;
  left: 50%;
  width: 40px;
  height: 4px;
  border-radius: 3px;
  z-index: 2;
  transform: translateX(-50%);
}
.page-intro .intro-text .btn-default {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  margin-top: 1rem;
}
.page-intro .intro-text .btn-default:hover,
.page-intro .intro-text .btn-default:focus,
.page-intro .intro-text .btn-default:active,
.page-intro .intro-text .btn-default:active:focus {
  border-color: #fff;
}
.item-video {
  height: 400px;
  cursor: pointer;
  overflow: hidden;
}
.item-video > div {
  position: relative;
  height: 100%;
}
.item-video > div:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(37, 49, 53, 0.4);
  opacity: 1;
  transition: opacity 200ms ease-in-out;
}
.item-video > div img {
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  height: 100%;
  display: block;
  width: auto;
  max-width: none;
  transform: translateX(-50%);
}
.item-video .item-meta {
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 0;
  left: 0;
  color: #fff;
  padding: 0 15px;
  transform: translateY(-50%);
}
.item-video:hover > div:after {
  opacity: 0.2;
}
.item-video .wb-stack {
  font-size: 60px;
}
.item-video .wb-stack .wb-circle {
  color: rgba(255, 255, 255, 0.2);
}
.item-video .wb-stack .wb-play {
  position: absolute;
  top: 51%;
  font-size: 80px;
  transform: translateY(-50%);
}
.item {
  position: relative;
  margin-bottom: 2rem;
}
.item:hover .product-photo,
.item:focus .product-photo,
.item:active .product-photo,
.item:active:focus .product-photo {
  transform: translateY(-30px);
  filter: drop-shadow(0 46px 7px #acacac);
}
.item:hover .btn-default,
.item:focus .btn-default,
.item:active .btn-default,
.item:active:focus .btn-default {
  color: #253135;
  border: 2px solid #24c196;
}
.item:hover .btn-default:after,
.item:focus .btn-default:after,
.item:active .btn-default:after,
.item:active:focus .btn-default:after {
  right: 15px;
}
.item .product-photo {
  filter: drop-shadow(0 16px 7px #acacac);
  transition: filter 200ms, transform 200ms;
  margin-bottom: 5rem;
}
.item-content {
  margin-bottom: 2.5rem;
}
.item-content h2 {
  margin-top: 0;
  margin-bottom: 5rem;
}
.item-content h3 {
  color: #fc3e41;
  margin-top: 4rem;
  margin-bottom: .5rem;
}
.item-content ul {
  list-style: none;
  padding: 0 0 0 20px;
}
.item-content ul li {
  position: relative;
  display: block;
  padding-left: 20px;
}
.item-content ul li:before {
  content: '\f026';
  font-family: "NiceToMeat";
  color: #fc3e41;
  font-size: 1rem;
  position: absolute;
  top: 8px;
  left: 0;
}
.item-content .meta {
  border-top: 1px solid rgba(104, 109, 115, 0.4);
  border-bottom: 2px solid rgba(104, 109, 115, 0.4);
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 20px 0;
}
.item-content .meta > div > div:not(:last-child) {
  border-right: 1px solid rgba(104, 109, 115, 0.4);
}
.item-content .meta > div > div h3 {
  margin-top: 0;
}
.item-agenda .date,
.item-agenda .time {
  margin-left: 60px;
}
.item-agenda .date {
  color: #fc3e41;
}
.item-agenda .time {
  font-size: 1.8rem;
  margin-bottom: 10px;
}
.item-agenda .item-agenda-content {
  position: relative;
  font-size: 1.6rem;
  line-height: 1.2;
  padding: 2.5rem;
  margin-left: 30px;
  border: 2px solid #686d73;
  border-radius: 35px;
}
.item-agenda .item-agenda-content h3 {
  color: #686d73;
  font-size: 1.8rem;
  font-weight: 700;
  margin: 0 0 1rem;
}
.item-agenda .item-agenda-content:before,
.item-agenda .item-agenda-content:after {
  content: '';
  position: absolute;
  top: 50%;
  left: -40px;
  height: 23px;
  width: 23px;
  background: #fff;
  border: 3px solid #fc3e41;
  border-radius: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.item-agenda .item-agenda-content:after {
  top: -50px;
  left: -30px;
  width: 3px;
  height: 100vh;
  background: #fc3e41;
  border: 0;
  border-radius: 0;
  transform: inherit;
  z-index: 1;
}
.item-agenda .item-agenda-content p:last-child {
  margin: 0;
}
.item-nieuwsbericht,
.item-vacature {
  background: #f8f4ec;
  padding: 15px 15px 30px;
  border: 5px solid #f8f4ec;
  transition: border 200ms;
  cursor: pointer;
}
.item-nieuwsbericht:hover,
.item-vacature:hover,
.item-nieuwsbericht:focus,
.item-vacature:focus,
.item-nieuwsbericht:active,
.item-vacature:active,
.item-nieuwsbericht:active:focus,
.item-vacature:active:focus {
  border: 5px solid rgba(37, 49, 53, 0.05);
}
.item-nieuwsbericht:hover .btn-default,
.item-vacature:hover .btn-default,
.item-nieuwsbericht:focus .btn-default,
.item-vacature:focus .btn-default,
.item-nieuwsbericht:active .btn-default,
.item-vacature:active .btn-default,
.item-nieuwsbericht:active:focus .btn-default,
.item-vacature:active:focus .btn-default {
  color: #253135;
  border: 2px solid #24c196;
}
.item-nieuwsbericht:hover .btn-default:after,
.item-vacature:hover .btn-default:after,
.item-nieuwsbericht:focus .btn-default:after,
.item-vacature:focus .btn-default:after,
.item-nieuwsbericht:active .btn-default:after,
.item-vacature:active .btn-default:after,
.item-nieuwsbericht:active:focus .btn-default:after,
.item-vacature:active:focus .btn-default:after {
  right: 15px;
}
.item-nieuwsbericht h3,
.item-vacature h3 {
  overflow: hidden;
}
.item-user img {
  border: 10px solid #fff;
  box-shadow: 0 0 1px rgba(37, 49, 53, 0.75);
  margin-bottom: 2rem;
}
.item-user p {
  font-size: 1.8rem;
  font-family: "myriad-pro-condensed", sans-serif;
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 0;
}
.item-user p span {
  display: block;
  color: rgba(37, 49, 53, 0.5);
  text-transform: none;
}
.index-overview h3,
.item-product h3 {
  text-transform: uppercase;
  margin-bottom: .5rem;
}
.index-overview p,
.item-product p {
  margin-bottom: 2rem;
}
.item-product {
  margin-bottom: 4rem;
  padding-bottom: 3rem;
  font-size: 1.8rem;
  border-bottom: 1px solid rgba(104, 109, 115, 0.5);
  text-align: center;
}
.item-product picture {
  height: 12rem;
  display: block;
}
.item-search {
  border: 2px solid rgba(104, 109, 115, 0.2);
  padding: 15px 30px 15px 15px;
  cursor: pointer;
}
.item-search:hover .btn-link {
  color: #24c196;
  text-decoration: none;
}
.item-search:hover .btn-link:after {
  right: -15px;
  color: #24c196;
}
.item-search h3 {
  margin-top: 1rem;
}
.item-overlay {
  padding: 30px 15px;
  background: #fff;
  border-radius: 25px;
  border: 1px solid rgba(104, 109, 115, 0.5);
  margin-top: 3rem;
  cursor: pointer;
}
.item-overlay h2 {
  font-size: 6rem;
  margin: 0 0 3rem;
  line-height: 1;
  color: #020818;
}
.item-overlay h2 span {
  display: block;
  color: rgba(2, 8, 24, 0.4);
  font-size: 3rem;
}
.item-overlay p {
  margin-bottom: 3rem;
}
.item-media {
  cursor: pointer;
  overflow: hidden;
}
.item-media .item-media-img {
  position: relative;
  overflow: hidden;
}
.item-media .item-media-img .item-meta {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.item-media:hover .wb-stack .wb-circle {
  color: rgba(255, 255, 255, 0.4);
}
.item-media:hover .wb-stack .wb-play {
  font-size: 8.3rem;
  top: 2%;
}
.item-media:hover img {
  transform: scale(1.1);
}
.item-media img {
  transition: transform 200ms;
}
.item-media h3 {
  margin: 1rem 0 0;
}
.item-media .wb-stack {
  font-size: 6rem;
}
.item-media .wb-stack .wb-circle {
  color: rgba(255, 255, 255, 0.2);
  transition: color 200ms;
}
.item-media .wb-stack .wb-play {
  font-size: 8.3rem;
  top: 2%;
}
.filter {
  margin-bottom: 5rem;
}
.filter li {
  display: inline-block;
}
.filter li .btn-default {
  border-color: rgba(37, 49, 53, 0.3);
  transition: border 200ms, background 200ms;
  transform: translateY(0);
}
.filter li .btn-default.active {
  border-color: #24c196;
}
.filter li .btn-default:hover,
.filter li .btn-default:active,
.filter li .btn-default:focus,
.filter li .btn-default:active:focus,
.filter li .btn-default.active {
  border-color: #24c196;
  background: rgba(36, 193, 150, 0.1);
}
.producten-show .standaard,
.en-producten-show .standaard,
.partners-show .standaard,
.en-partners-show .standaard {
  position: relative;
}
.producten-show .standaard .product-logo,
.en-producten-show .standaard .product-logo,
.partners-show .standaard .product-logo,
.en-partners-show .standaard .product-logo,
.producten-show .standaard .partner-logo,
.en-producten-show .standaard .partner-logo,
.partners-show .standaard .partner-logo,
.en-partners-show .standaard .partner-logo {
  position: absolute;
  top: -35px;
  right: 5%;
  background: #fff;
  padding: 10px;
}
.producten-show h2.title:after,
.en-producten-show h2.title:after,
.partners-show h2.title:after,
.en-partners-show h2.title:after,
.producten-show .h2.title:after,
.en-producten-show .h2.title:after,
.partners-show .h2.title:after,
.en-partners-show .h2.title:after {
  top: 130%;
}
.featured .image-line {
  position: relative;
  margin-bottom: 0;
}
.featured .image-line:after {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  bottom: 50%;
  right: 0;
  height: 2px;
  background-color: rgba(104, 109, 115, 0.3);
  transform: translateY(-50%);
}
.featured .image-line li {
  display: inline-block;
  position: relative;
  padding: 0 .7rem;
  cursor: pointer;
}
.featured .image-line li:after {
  content: '';
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border: 2px solid rgba(104, 109, 115, 0.3);
  background: #fff;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
}
.featured .image-line li:hover img {
  transform: translateY(-10px);
}
.featured .image-line li img {
  display: inline-block;
  position: relative;
  padding: 33px 0;
  filter: drop-shadow(0 4px 4px #acacac);
  transition: filter 200ms, transform 200ms;
  z-index: 2;
}
.featured .featured-information h2 {
  margin: 0 0 2rem;
}
.featured .featured-information a {
  margin-top: 1.5rem;
}
.featured .featured-information a:hover,
.featured .featured-information a:focus,
.featured .featured-information a:active:focus {
  color: #686d73;
  text-decoration: none;
}
.featured .featured-information .btn-default {
  padding: 0 25px 0 20px;
}
.featured .featured-information .btn-default:after {
  right: 5px;
}
.featured-detail {
  background: #253135;
  color: #fff;
}
.featured-detail h2.title,
.featured-detail .h2.title {
  color: #fff;
}
.featured-detail h2.title span,
.featured-detail .h2.title span {
  background-color: #253135;
}
.featured-detail h2.title:after,
.featured-detail .h2.title:after {
  content: '';
  position: absolute;
  background-color: #fff;
}
.slick-slide img {
  filter: drop-shadow(0 10px 10px #acacac);
  padding-left: 15px;
}
.product-content {
  margin-top: 2rem;
}
.product-content img {
  margin-bottom: 2rem;
}
.product-content:not(:last-child) .product-text {
  border-bottom: 1px solid rgba(104, 109, 115, 0.5);
  padding-bottom: 2rem;
  margin-bottom: 2rem;
}
.agenda {
  padding-top: 0;
  padding-bottom: 5rem;
  overflow: hidden;
}
.agenda ul {
  list-style: none;
  border-right: 3px solid #fc3e41;
  padding-right: 2.3rem;
}
.agenda ul li {
  color: #fc3e41;
  font-family: "myriad-pro-condensed", sans-serif;
}
.agenda ul p {
  color: #253135;
}
.index-overview {
  overflow: hidden;
}
.index-overview picture {
  margin-left: -15px;
}
.news-index {
  z-index: 3;
}
.news-index a:hover,
.news-index:active,
.news-index:active:focus {
  color: #253135;
  text-decoration: none;
}
.news-index p {
  margin: 2rem 0 1.5rem 0;
}
.chefkok h2 {
  line-height: 1;
  margin: 0 0 1.2rem;
}
.chefkok h3 {
  margin: 0 0 3rem;
}
.chefkok .item-media .wb-stack {
  font-size: 4rem;
}
.chefkok .item-media .wb-stack .wb-play {
  font-size: 5.3rem;
}
.chefkok .item-media:nth-child(n+3) {
  display: none;
}
.timeline {
  background: #f8f4ec;
  position: relative;
}
.timeline > div {
  position: relative;
}
.timeline > div:before,
.timeline > div:after {
  content: '';
  position: absolute;
  top: 8rem;
  left: 0;
  width: 10rem;
  height: 30rem;
  background-image: url('/theme/img/fade-left.png');
  background-size: contain;
  background-repeat: repeat-y;
  z-index: 2;
}
.timeline > div:after {
  background-image: url('/theme/img/fade-right.png');
  left: inherit;
  right: 0;
}
.timeline .title span {
  background: #f8f4ec;
}
.timeline .item-timeline {
  position: relative;
  font-size: 1.8rem;
  font-style: italic;
}
.timeline .item-timeline:after {
  content: '';
  position: absolute;
  top: 10.5rem;
  left: 0;
  right: 0;
  background-image: url('/theme/img/dottedline.png');
  background-size: contain;
  background-repeat: repeat-x;
  height: 22px;
  width: 100%;
}
.timeline .item-timeline h2 {
  margin: 0 0 6rem;
}
.timeline .item-timeline h2 span {
  display: inline-block;
  font-size: 2.8rem;
  font-style: normal;
  color: #253135;
  background: #fff;
  line-height: 2.5;
  border-radius: 50%;
  height: 9rem;
  width: 9rem;
  box-shadow: 0 0 1px rgba(37, 49, 53, 0.5);
  border: 10px solid #f8f4ec;
}
.timeline ul li {
  display: inline-block;
}
.timeline ul li .wb {
  font-size: 5rem;
}
.slider-history .slides {
  display: flex;
  opacity: 0;
  transition: opacity ease-out 400ms;
}
.slider-history .slides.slick-initialized {
  opacity: 1;
}
.slider-history .slides > div {
  width: 100%;
}
.slider-history .slides .slick-slide {
  height: auto;
  padding: 0 2rem;
}
.form-job .question-header,
.form-job .question {
  font-family: "myriad-pro-condensed", sans-serif;
  font-size: 2.1rem;
  color: #fff;
  background: #24c196;
  border-radius: 35px;
  padding: 5px 3rem;
  margin-bottom: 15px;
}
.form-job .question {
  font-family: "adobe-text-pro", serif;
  font-size: 1.6rem;
  color: #253135;
  background: rgba(37, 49, 53, 0.1);
  padding: 5px 2rem 5px 3rem;
  margin-bottom: 0;
}
.form-job .question .radio-inline {
  padding-left: 10px;
}
.form-job .question .radio-inline + .radio-inline {
  margin-left: 5px;
}
.form-job .form-group-enquete {
  margin-bottom: 5px;
}
.instagram hr {
  border: none;
  background-image: linear-gradient(to right, rgba(37, 49, 53, 0.25) 75%, #fff 0);
  background-position: bottom;
  background-size: 20px 2px;
  background-repeat: repeat-x;
  height: 2px;
}
.instagram ul {
  position: relative;
  padding: 3rem 0;
}
.instagram ul:before,
.instagram ul:after {
  content: '';
  background: #fc3e41;
  position: absolute;
  top: 0;
  left: 50%;
  width: 40px;
  height: 4px;
  border-radius: 3px;
  transform: translateX(-50%);
}
.instagram ul:after {
  content: '';
  background: #fc3e41;
  top: inherit;
  bottom: 0;
}
.instagram ul.slick-nav-wrap {
  padding: 1rem 0;
}
.instagram ul.slick-nav-wrap li {
  padding: 0;
}
.instagram ul.slick-nav-wrap li .wb {
  font-size: 3rem;
  color: #686d73;
}
.instagram ul.slick-nav-wrap:before,
.instagram ul.slick-nav-wrap:after {
  content: none;
}
.instagram ul li {
  display: inline-block;
  padding: 0 0.5rem;
  transition: color 200ms;
}
.instagram ul li .wb {
  font-size: 4.6rem;
}
.instagram ul li:hover .wb,
.instagram ul li:active .wb,
.instagram ul li:focus .wb,
.instagram ul li:active:focus .wb {
  color: #fb0c10;
}
.instagram .slider-instagram .item-instagram {
  margin-bottom: 0;
}
.instagram .slider-instagram .slick-slide img {
  filter: none;
  height: 250px;
  width: 100%;
  object-fit: cover;
  padding: 0;
}
.contact {
  background: #f8f4ec;
}
.contact h2 a {
  color: #686d73;
  text-decoration: underline;
  transition: color 200ms;
}
.contact h2 a:hover,
.contact h2 a:focus,
.contact h2 a:active,
.contact h2 a:active:focus {
  color: #fc3e41;
}
.contact .col-chefs {
  background: #fff;
}
.contact .main-img {
  padding: 0;
}
footer {
  color: #fff;
}
footer h3 {
  font-size: 2.8rem;
  margin-bottom: 15px;
}
footer hr {
  border-color: rgba(255, 255, 255, 0.2);
}
footer section {
  padding: 0;
}
footer section:after {
  content: none !important;
}
footer .footer-content {
  padding: 5rem 0;
  background: rgba(104, 109, 115, 0.4);
  color: #253135;
}
footer .footer-info {
  font-size: 1.6rem;
  background: #020818;
  padding: 3rem 0;
}
footer .footer-info .wb {
  color: #fc3e41;
  padding-right: 15px;
}
footer .footer-naw a {
  color: #fff;
  text-decoration: underline;
  transition: color 200ms;
}
footer .footer-naw a:hover {
  color: #fc3e41;
}
footer .footer-sub {
  font-size: 1.6rem;
  background: #fc3e41;
  line-height: 3;
  padding: 3rem 0 0;
}
footer .footer-sub p {
  margin: 0;
}
footer .footer-sub ul {
  display: inline-block;
  background: #c3c5c7;
  margin: 0;
}
footer .footer-sub ul li {
  display: inline-block;
}
footer .footer-sub ul li a {
  display: inline-block;
  color: #253135;
  padding: 0 1.8rem;
  background: #c3c5c7;
  transition: background 200ms, color 200ms;
}
footer .footer-sub ul li a:hover,
footer .footer-sub ul li a:focus,
footer .footer-sub ul li a:active,
footer .footer-sub ul li a:active:focus {
  color: #fc3e41;
  background: #fff;
}
footer .footer-sub ul.footer-links {
  background: transparent;
}
footer .footer-sub ul.footer-links li {
  display: block;
  line-height: 1.6;
}
footer .footer-sub ul.footer-links li a {
  color: #fff;
  text-decoration: underline;
  background: transparent;
  padding: 0;
  line-height: 1.6;
}
footer .footer-sub ul.footer-links li a:hover,
footer .footer-sub ul.footer-links li a:focus,
footer .footer-sub ul.footer-links li a:active,
footer .footer-sub ul.footer-links li a:active:focus {
  color: #020818;
  background: transparent;
}
@media (min-width: 480px) {
  .producten-show h2.title:after,
  .en-producten-show h2.title:after,
  .partners-show h2.title:after,
  .en-partners-show h2.title:after,
  .producten-show .h2.title:after,
  .en-producten-show .h2.title:after,
  .partners-show .h2.title:after,
  .en-partners-show .h2.title:after {
    top: 80%;
  }
  .item-product {
    border: none;
  }
  .item-product h3 {
    text-overflow: ellipsis;
    height: 6rem;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .product-content,
  .featured-detail {
    margin-top: 0;
  }
  .chefkok .item-media:nth-child(-n+3) {
    display: block;
  }
}
@media (min-width: 768px) {
  html {
    font-size: 8px;
  }
  html,
  body {
    height: 100%;
    width: 100%;
  }
  h2,
  .h2 {
    font-size: 5rem;
  }
  h3 span,
  .h3 span {
    font-size: 3.2rem;
    color: #fff;
  }
  .btn-default.btn-classic,
  .btn-default.btn-select,
  .btn-default.btn-gold {
    padding: 0 20px 0 55px;
  }
  .btn-default.btn-classic:after,
  .btn-default.btn-select:after,
  .btn-default.btn-gold:after {
    content: '';
    left: 10px;
    transform: translate(0, -50%);
  }
  .btn-default,
  .btn-primary {
    padding: 0 45px 1px 30px;
  }
  .btn-link,
  .btn-prev {
    font-size: 1.8rem;
  }
  .modal-dialog-home {
    width: 75%;
  }
  section {
    padding: 8rem 0;
  }
  section h2 {
    margin-bottom: 6rem;
  }
  .section-header {
    margin-bottom: 4rem;
  }
  .parallax-wrap {
    height: 300px;
  }
  .form-job .question-header,
  .form-job .question {
    padding: .6rem 3rem 1rem;
  }
  .form-job .question {
    padding: 1rem 2rem .5rem 3rem;
    margin-bottom: 0;
  }
  .form-job .question .radio-inline {
    padding-left: 20px;
  }
  .form-job .question .radio-inline + .radio-inline {
    margin-left: 5px;
  }
  main {
    margin-top: 80px;
    min-height: 500px;
  }
  header {
    height: 80px;
  }
  .header-content .btn-default:after {
    content: none;
  }
  .header-content .form-control {
    border: 2px solid #686d73;
  }
  .header-content > div:not(:last-child) {
    padding: 20px 10px 0;
    border-right: 2px solid rgba(104, 109, 115, 0.2);
  }
  .header-content > div.search {
    width: 300px;
  }
  .header-content > div.primary-class {
    border: none;
  }
  .header-content > div.primary-class .btn-primary {
    padding: 0 30px;
  }
  .header-content .switch {
    color: #253135;
  }
  .header-content .switch-toggle {
    display: inline-block;
    background: #24c196;
    border-radius: 35px;
    margin: 3px 0 0 0;
    padding: 5px;
    box-shadow: inset 0 0 10px #1c9675;
  }
  .header-content .switch-toggle input {
    position: absolute;
  }
  .header-content .switch-toggle input:checked + label {
    color: #24c196;
    background-color: #fff;
    box-shadow: 0 0 10px 0 #1c9675;
  }
  .header-content .switch-toggle label {
    display: inline-block;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    border-radius: 35px;
    padding: 2px 10px;
    margin: 0;
    transition: all 0.1s ease-in-out;
  }
  .header-content .switch-toggle label:hover {
    color: #fff;
    cursor: pointer;
  }
  .menu-trigger {
    padding: 22px;
  }
  .menu-wrapper:after {
    content: '';
    background: url('/theme/img/beeldmerk.png') center no-repeat;
    background-size: 100%;
    width: 700px;
    height: 400px;
  }
  .menu-wrapper .main-menu {
    height: calc(100vh - 80px);
  }
  .menu-wrapper .main-menu:before {
    top: -50px;
  }
  .menu-wrapper .main-menu li:hover,
  .menu-wrapper .main-menu li:focus,
  .menu-wrapper .main-menu li:active,
  .menu-wrapper .main-menu li:active:focus,
  .menu-wrapper .main-menu li.active {
    background: #101517;
  }
  .menu-wrapper .main-menu li a {
    font-size: 2.6rem;
    padding: 2.6rem 0;
  }
  .menu-wrapper .main-menu li ul {
    padding: 0;
  }
  .menu-wrapper .main-menu li ul li a {
    font-size: 1.8rem;
    padding: 0 3rem;
    letter-spacing: 5px;
  }
  .menu-wrapper .sub-menu li {
    display: inline-block;
    border-top: none;
  }
  .menu-wrapper .sub-menu li:not(:last-child) {
    border-right: 2px solid #fc3e41;
  }
  .menu-wrapper .sub-menu li a {
    color: rgba(255, 255, 255, 0.9);
    font-size: 1.8rem;
    padding: 0 3rem;
    line-height: 1;
    transition: color 200ms;
  }
  .menu-wrapper .sub-menu li a:hover,
  .menu-wrapper .sub-menu li a:focus,
  .menu-wrapper .sub-menu li a:active,
  .menu-wrapper .sub-menu li a:active:focus,
  .menu-wrapper .sub-menu li a.active {
    background: transparent;
    color: #fc3e41;
    text-decoration: none;
  }
  .switch {
    padding: 20px 0 0;
    border: none;
  }
  .header-content .dropdown-toggle {
    padding: 5px 18px;
  }
  .dropdown-menu {
    top: 65px;
    left: 10px;
    min-width: 135px;
  }
  .page-intro h1 {
    margin: 0 0 5rem;
  }
  .page-intro .intro-text {
    /* .wb-events-icons-ready &:before {
                content: '\f020';
                font-size: 80%;
                font-family: @icons;
                color: @grey;
                position: absolute;
                top: 10%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
            }*/
  }
  .page-intro .intro-text > div:before {
    top: -10%;
  }
  .page-intro .intro-text .btn-default:before {
    top: -40px;
  }
  .featured .image-line li:after {
    content: '';
    width: 120px;
    height: 120px;
  }
  .news-index img {
    border: 10px solid rgba(37, 49, 53, 0.05);
  }
  .news-index p {
    margin: 2rem 0 3rem 0;
  }
  .chefkok .item-img {
    position: relative;
    margin-top: 3rem;
    cursor: pointer;
  }
  .chefkok .item-img:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url('/theme/img/mask-playbtn.png') center no-repeat;
    background-size: 100%;
  }
  .chefkok .item-media:nth-child(-n+4) {
    display: block;
  }
  .item {
    margin-bottom: 4rem;
  }
  .item-agenda .meta {
    -ms-transform: translateY(-50%);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 0;
  }
  .item-agenda .date,
  .item-agenda .time {
    text-align: right;
    margin: 0;
  }
  .item-agenda .item-agenda-content {
    margin: 0;
  }
  .item-agenda .item-agenda-content:before,
  .item-agenda .item-agenda-content:after {
    top: 50%;
    left: -60px;
    height: 23px;
    width: 23px;
  }
  .item-agenda .item-agenda-content:after {
    top: 0;
    left: -50px;
    width: 3px;
    height: 100vh;
  }
  .item-content {
    margin-bottom: 10rem;
  }
  .index-overview h3,
  .item-product h3 {
    margin-bottom: 2rem;
  }
  .item-product picture {
    height: 18rem;
  }
  .item-product p {
    height: 15.2rem;
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .item-user p {
    font-size: 2.1rem;
  }
  .item-search:after {
    content: '\f01a';
    font-family: "NiceToMeat";
    position: absolute;
    top: 50%;
    right: -50px;
    width: 50px;
    height: 50px;
    font-size: 32px;
    color: #24c196;
    text-align: center;
    border: 2px solid rgba(104, 109, 115, 0.2);
    border-radius: 50%;
    background: #fff;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 200ms, right 200ms;
  }
  .item-search:hover:after {
    content: '\f01a';
    right: -25px;
    opacity: 1;
  }
  .item-overlay {
    padding: 6rem 5rem;
  }
  .item-overlay h2 {
    position: relative;
    font-size: 6rem;
    margin: 2rem 0 3rem;
    line-height: 1;
    color: #020818;
  }
  .item-overlay h2 span {
    display: block;
    color: rgba(2, 8, 24, 0.4);
    font-size: 3rem;
  }
  .item-overlay p {
    margin-bottom: 3rem;
  }
  footer h3 {
    margin-bottom: 3rem;
  }
  footer .footer-info {
    padding: 7rem 0;
  }
  footer .footer-info .footer-naw > div:not(:last-child) {
    position: relative;
  }
  footer .footer-info .footer-naw > div:not(:last-child):after {
    content: '';
    position: absolute;
    top: 0;
    right: 20px;
    height: 100%;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  footer .footer-sub {
    padding: 0;
  }
  footer .footer-sub ul.footer-links li {
    display: inline-block;
    position: relative;
    padding: 0 1rem 0 0;
  }
  footer .footer-sub ul.footer-links li:not(:first-child) {
    padding: 0 1rem;
  }
  footer .footer-sub ul.footer-links li:not(:last-child):after {
    content: '-';
    color: #fff;
    position: absolute;
    right: -2px;
  }
  footer .footer-sub ul li a {
    padding: 0 1rem;
  }
  footer .form-inline .form-control {
    width: 22rem;
  }
  .producten-show .standaard .product-logo,
  .en-producten-show .standaard .product-logo,
  .partners-show .standaard .product-logo,
  .en-partners-show .standaard .product-logo,
  .producten-show .standaard .partner-logo,
  .en-producten-show .standaard .partner-logo,
  .partners-show .standaard .partner-logo,
  .en-partners-show .standaard .partner-logo {
    top: -50px;
    padding: 10px;
  }
  .product-content {
    position: relative;
    margin-top: 6rem;
  }
  .product-content:not(:last-child) .product-text {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .product-content .product-image,
  .product-content .product-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .product-content:nth-child(even) .product-image {
    right: 0;
  }
  .product-content:nth-child(odd) > div:nth-child(2) {
    right: 0;
  }
  .special-home-particulier footer h3 {
    margin-bottom: 0;
  }
  .special-home-particulier footer .form-newsletter {
    margin-top: 16px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 9px;
  }
  h1,
  .h1 {
    font-size: 9rem;
  }
  .btn-default.btn-classic,
  .btn-default.btn-select,
  .btn-default.btn-gold {
    padding: 0 10px 0 40px;
  }
  .btn-default.btn-classic:after,
  .btn-default.btn-select:after,
  .btn-default.btn-gold:after {
    content: '';
    top: 23px;
    left: 5px;
    width: 30px;
  }
  .btn-default,
  .btn-primary {
    padding: 0 45px 0 30px;
  }
  .form-control {
    padding: 6px 20px;
  }
  .overlay-popup {
    min-height: 40vh;
    width: 355px;
  }
  header {
    height: 100px;
  }
  .header-content > div:not(:last-child) {
    padding: 24px 7px 0;
  }
  .header-content > div.primary-class .btn-primary {
    line-height: 2.2;
  }
  .header-content > div.search {
    width: 500px;
  }
  main {
    margin-top: 100px;
  }
  .menu-trigger {
    padding: 32px;
  }
  .menu-wrapper .main-menu {
    height: calc(100vh - 100px);
  }
  .switch {
    padding: 30px 0 0;
  }
  .switch > div:first-child {
    display: inline-block;
  }
  .dropdown-menu {
    top: 80px;
    left: 30px;
    min-width: 160px;
  }
  .page-intro h1 {
    margin: 0 0 5rem;
  }
  .page-intro .intro {
    height: 600px;
  }
  .page-intro .intro-text {
    height: 600px;
  }
  .page-intro .intro-text:before {
    top: 20%;
  }
  .item-video {
    height: 600px;
  }
  .item-video .item-meta {
    padding: 0 100px;
  }
  .item-video .item-meta .lead {
    color: #fff;
  }
  .item-video .item-meta .lead:hover,
  .item-video .item-meta .lead:focus,
  .item-video .item-meta .lead:active,
  .item-video .item-meta .lead:active:focus {
    text-decoration: none;
  }
  .index-overview > div {
    height: 90vh;
    overflow: hidden;
  }
  .index-overview > div > div:first-child {
    height: 100%;
    overflow: hidden;
  }
  .index-overview > div > div:first-child picture img {
    position: absolute;
    top: 0;
    left: 50%;
    right: 0;
    height: 100%;
    display: block;
    width: auto;
    max-width: none;
    transform: translateX(-50%);
  }
  .index-overview > div > div:last-child {
    height: 100%;
    overflow: auto;
    padding: 0 40px;
  }
  .item-user img {
    border: 15px solid #fff;
  }
  .filter li .btn {
    font-size: 1.8rem;
    line-height: 2.5;
  }
  footer .footer-content {
    padding: 7rem 0;
  }
  footer .footer-sub {
    padding: 0;
  }
  footer .footer-sub ul li a {
    padding: 0 1.5rem;
  }
  .special-home-particulier footer .form-newsletter {
    margin-top: 10px;
  }
  .chefkok .item-media:nth-child(-n+6) {
    display: block;
  }
}
@media (min-width: 1200px) {
  html {
    font-size: 10px;
  }
  section {
    padding: 10rem 0;
  }
  .parallax-wrap {
    height: 400px;
  }
  .btn-default.btn-classic,
  .btn-default.btn-select,
  .btn-default.btn-gold {
    padding: 0 20px 0 55px;
  }
  .btn-default.btn-classic:after,
  .btn-default.btn-select:after,
  .btn-default.btn-gold:after {
    content: '';
    left: 10px;
    width: 36px;
  }
  .btn-link,
  .btn-prev {
    font-size: 2.1rem;
  }
  .header-content > div.primary-class .btn-primary {
    line-height: 2.1;
    width: 225px;
  }
  .filter li .btn {
    font-size: 2.1rem;
    line-height: 2.1;
  }
  .page-intro .intro-text > div {
    left: 15%;
    right: 15%;
  }
  .featured .featured-information .btn-default {
    padding: 0 45px 0 30px;
  }
  .featured .featured-information .btn-default:after {
    right: 25px;
  }
  footer .footer-info .footer-naw > div:not(:last-child):after {
    right: 50px;
  }
  footer .footer-content .form-inline .form-control {
    width: 30rem;
  }
}
@media (min-width: 1400px) {
  h2,
  .h2 {
    font-size: 6rem;
  }
  .modal-dialog {
    width: 50%;
  }
  .page-intro .intro-text {
    height: 860px;
  }
  .item-video {
    height: 860px;
  }
  .item-product p {
    padding: 0 3rem;
  }
  .index-overview .filter li {
    margin: 0 1rem;
  }
  .header-content > div:not(:last-child) {
    padding: 24px 15px 0;
  }
}
