.vertical-align(@position: relative) {
  position: @position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.line-clamp(@height, @lines) {
  text-overflow: ellipsis;
  height: @height;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: @lines;
  -webkit-box-orient: vertical;
}

.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
  text-shadow: @string;
}

.box-shadow (@string) {
  -webkit-box-shadow: @string;
  -moz-box-shadow: @string;
  box-shadow: @string;
}

.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
  -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  -moz-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
  box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.box-sizing (@type: border-box) {
  -webkit-box-sizing: @type;
  -moz-box-sizing: @type;
  box-sizing: @type;
}

.border-radius (@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;

  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
  -webkit-border-top-right-radius: @topright;
  -webkit-border-bottom-right-radius: @bottomright;
  -webkit-border-bottom-left-radius: @bottomleft;
  -webkit-border-top-left-radius: @topleft;

  -moz-border-radius-topright: @topright;
  -moz-border-radius-bottomright: @bottomright;
  -moz-border-radius-bottomleft: @bottomleft;
  -moz-border-radius-topleft: @topleft;

  border-top-right-radius: @topright;
  border-bottom-right-radius: @bottomright;
  border-bottom-left-radius: @bottomleft;
  border-top-left-radius: @topleft;

  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.opacity (@opacity: 0.5) {
  -webkit-opacity: @opacity;
  -moz-opacity: @opacity;
  opacity: @opacity;
}

.gradient (@startColor: #eee, @endColor: white) {
  background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
  background: -webkit-linear-gradient(top, @startColor, @endColor);
  background: -moz-linear-gradient(top, @startColor, @endColor);
  background: -ms-linear-gradient(top, @startColor, @endColor);
  background: -o-linear-gradient(top, @startColor, @endColor);
  background: linear-gradient(to bottom, @startColor, @endColor);
}

.horizontal-gradient (@startColor: #eee, @endColor: white) {
  background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
  background-image: -webkit-linear-gradient(left, @startColor, @endColor);
  background-image: -moz-linear-gradient(left, @startColor, @endColor);
  background-image: -ms-linear-gradient(left, @startColor, @endColor);
  background-image: -o-linear-gradient(left, @startColor, @endColor);
  background-image: linear-gradient(to right, @startColor, @endColor);
}

.multi-gradient ( ... ) {
  background-image: -webkit-linear-gradient(@arguments);
  background-image: -moz-linear-gradient(@arguments);
  background-image: -ms-linear-gradient(@arguments);
  background-image: -o-linear-gradient(@arguments);
  background-image: linear-gradient(@arguments);
}

.striped(@color, @angle: -45deg) {
  background-color: @color;
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(.25, rgba(255, 255, 255, .15)), color-stop(.25, transparent), color-stop(.5, transparent), color-stop(.5, rgba(255, 255, 255, .15)), color-stop(.75, rgba(255, 255, 255, .15)), color-stop(.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -ms-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: -o-linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(@angle, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
}

.animation (@name, @duration: 300ms, @delay: 0, @ease: ease) {
  -webkit-animation: @name @duration @delay @ease;
  -moz-animation: @name @duration @delay @ease;
  -ms-animation: @name @duration @delay @ease;
  animation: @name @duration @delay @ease;
}

.transition (@transition) {
  -webkit-transition: @transition;
  -moz-transition: @transition;
  -ms-transition: @transition;
  -o-transition: @transition;
  transition: @transition;
}

.transform(@string) {
  -webkit-transform: @string;
  -moz-transform: @string;
  -ms-transform: @string;
  -o-transform: @string;
  transform: @string;
}

.scale (@factor) {
  -webkit-transform: scale(@factor);
  -moz-transform: scale(@factor);
  -ms-transform: scale(@factor);
  -o-transform: scale(@factor);
  transform: scale(@factor);
}

.matrix(...) {
  -webkit-transform: matrix(@arguments);
  -moz-transform: matrix(@arguments);
  -ms-transform: matrix(@arguments);
  -o-transform: matrix(@arguments);
  transform: matrix(@arguments);
}

.rotate (@deg) {
  -webkit-transform: rotate(@deg);
  -moz-transform: rotate(@deg);
  -ms-transform: rotate(@deg);
  -o-transform: rotate(@deg);
  transform: rotate(@deg);
}

.skew (@deg, @deg2) {
  -webkit-transform: skew(@deg, @deg2);
  -moz-transform: skew(@deg, @deg2);
  -ms-transform: skew(@deg, @deg2);
  -o-transform: skew(@deg, @deg2);
  transform: skew(@deg, @deg2);
}

.translate (@x, @y:0) {
  -webkit-transform: translate(@x, @y);
  -moz-transform: translate(@x, @y);
  -ms-transform: translate(@x, @y);
  -o-transform: translate(@x, @y);
  transform: translate(@x, @y);
}

.translate3d (@x, @y: 0, @z: 0) {
  -webkit-transform: translate3d(@x, @y, @z);
  -moz-transform: translate3d(@x, @y, @z);
  -ms-transform: translate3d(@x, @y, @z);
  -o-transform: translate3d(@x, @y, @z);
  transform: translate3d(@x, @y, @z);
}

.perspective (@value: 1000) {
  -webkit-perspective: @value;
  -moz-perspective: @value;
  -ms-perspective: @value;
  perspective: @value;
}

.transform-origin (@x:center, @y:center) {
  -webkit-transform-origin: @x @y;
  -moz-transform-origin: @x @y;
  -ms-transform-origin: @x @y;
  -o-transform-origin: @x @y;
  transform-origin: @x @y;
}

.appearance(@appearance) {
  -webkit-appearance: @appearance;
  -moz-appearance: @appearance;
  -ms-appearance: @appearance;
  -o-appearance: @appearance;
  appearance: @appearance;
}

.backface-visibility(...) {
  -webkit-backface-visibility: @arguments;
  -moz-backface-visibility: @arguments;
  -ms-backface-visibility: @arguments;
  -o-backface-visibility: @arguments;
  backface-visibility: @arguments;
}

.user-select(...) {
  -webkit-touch-callout: @arguments;

  -webkit-user-select: @arguments;
  -moz-user-select: @arguments;
  -ms-user-select: @arguments;
  -o-user-select: @arguments;
  user-select: @arguments;
}

.column-count(...) {
  -webkit-column-count: @arguments;
  -moz-column-count: @arguments;
  column-count: @arguments;
}

.column-gap(...) {
  -webkit-column-gap: @arguments;
  -moz-column-gap: @arguments;
  column-gap: @arguments;
}

.perspective-origin(...) {
  -webkit-perspective-origin: @arguments;
  -moz-perspective-origin: @arguments;
  -ms-perspective-origin: @arguments;
  -o-perspective-origin: @arguments;
  perspective-origin: @arguments;
}

.grayscale(@percentage: 100) {
  @p: @percentage / 100;
  @v: @p / 3;
  @v2: (@v + 1 - @p);

  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'@{v2} @{v} @{v} 0 0 @{v} @{v2} @{v} 0 0 @{v} @{v} @{v2} 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
  filter: gray;
  -webkit-filter: grayscale(@percentage * 1%);
}

.filter(...) {
  -webkit-filter: @arguments;
  -moz-filter: @arguments;
  filter: @arguments;
}

.font-smoothing(@value: true) when (@value = true) {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-smoothing(@value: true) when (@value = false) {
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;

}

.placeholder(@rules) {

  &::-webkit-input-placeholder {
    @rules();
  }
  &:-moz-placeholder {
    @rules();
  }
  &::-moz-placeholder {
    @rules();
  }
  &:-ms-input-placeholder {
    @rules();
  }
}

.vertical-align(@position: relative) {
  position: @position;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* Cubic */
@easeInCubic     : cubic-bezier(0.550, 0.055, 0.675, 0.190);
@easeOutCubic    : cubic-bezier(0.215, 0.610, 0.355, 1.000);
@easeInOutCubic  : cubic-bezier(0.645, 0.045, 0.355, 1.000);

// Circ
@easeInCirc      : cubic-bezier(0.600, 0.040, 0.980, 0.335);
@easeOutCirc     : cubic-bezier(0.075, 0.820, 0.165, 1.000);
@easeInOutCirc   : cubic-bezier(0.785, 0.135, 0.150, 0.860);

// Expo
@easeInExpo      : cubic-bezier(0.950, 0.050, 0.795, 0.035);
@easeOutExpo     : cubic-bezier(0.190, 1.000, 0.220, 1.000);
@easeInOutExpo   : cubic-bezier(1.000, 0.000, 0.000, 1.000);

// Quad
@easeInQuad      : cubic-bezier(0.550, 0.085, 0.680, 0.530);
@easeOutQuad     : cubic-bezier(0.250, 0.460, 0.450, 0.940);
@easeInOutQuad   : cubic-bezier(0.455, 0.030, 0.515, 0.955);

// Quart
@easeInQuart     : cubic-bezier(0.895, 0.030, 0.685, 0.220);
@easeOutQuart    : cubic-bezier(0.165, 0.840, 0.440, 1.000);
@easeInOutQuart  : cubic-bezier(0.770, 0.000, 0.175, 1.000);

// Quint
@easeInQuint     : cubic-bezier(0.755, 0.050, 0.855, 0.060);
@easeOutQuint    : cubic-bezier(0.230, 1.000, 0.320, 1.000);
@easeInOutQuint  : cubic-bezier(0.860, 0.000, 0.070, 1.000);

// Sine
@easeInSine      : cubic-bezier(0.470, 0.000, 0.745, 0.715);
@easeOutSine     : cubic-bezier(0.390, 0.575, 0.565, 1.000);
@easeInOutSine   : cubic-bezier(0.445, 0.050, 0.550, 0.950);

// Back
@easeInBack      : cubic-bezier(0.600, -0.280, 0.735, 0.045);
@easeOutBack     : cubic-bezier(0.175, 0.885, 0.320, 1.275);
@easeInOutBack   : cubic-bezier(0.680, -0.550, 0.265, 1.550);