.col-xxs {
    .col-xxs-1, .col-xxs-2, .col-xxs-3, .col-xxs-4, .col-xxs-5, .col-xxs-6, .col-xxs-7, .col-xxs-8, .col-xxs-9, .col-xxs-10, .col-xxs-11, .col-xxs-12 {
        float: left;
    }

    .col-xxs-12 {
        width: 100%;
    }

    .col-xxs-11 {
        width: 91.66666667%;
    }

    .col-xxs-10 {
        width: 83.33333333%;
    }

    .col-xxs-9 {
        width: 75%;
    }

    .col-xxs-8 {
        width: 66.66666667%;
    }

    .col-xxs-7 {
        width: 58.33333333%;
    }

    .col-xxs-6 {
        width: 50%;
    }

    .col-xxs-5 {
        width: 41.66666667%;
    }

    .col-xxs-4 {
        width: 33.33333333%;
    }

    .col-xxs-3 {
        width: 25%;
    }

    .col-xxs-2 {
        width: 16.66666667%;
    }

    .col-xxs-1 {
        width: 8.33333333%;
    }

    .col-xxs-offset-12 {
        margin-left: 100%;
    }

    .col-xxs-offset-11 {
        margin-left: 91.66666667%;
    }

    .col-xxs-offset-10 {
        margin-left: 83.33333333%;
    }

    .col-xxs-offset-9 {
        margin-left: 75%;
    }

    .col-xxs-offset-8 {
        margin-left: 66.66666667%;
    }

    .col-xxs-offset-7 {
        margin-left: 58.33333333%;
    }

    .col-xxs-offset-6 {
        margin-left: 50%;
    }

    .col-xxs-offset-5 {
        margin-left: 41.66666667%;
    }

    .col-xxs-offset-4 {
        margin-left: 33.33333333%;
    }

    .col-xxs-offset-3 {
        margin-left: 25%;
    }

    .col-xxs-offset-2 {
        margin-left: 16.66666667%;
    }

    .col-xxs-offset-1 {
        margin-left: 8.33333333%;
    }

    .col-xxs-offset-0 {
        margin-left: 0;
    }

    .hidden-xxs {
        display: none !important;
    }
}

.col-xl {
    .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12 {
        float: left;
    }

    .col-xl-12 {
        width: 100%;
    }
    .col-xl-11 {
        width: 91.66666667%;
    }
    .col-xl-10 {
        width: 83.33333333%;
    }
    .col-xl-9 {
        width: 75%;
    }
    .col-xl-8 {
        width: 66.66666667%;
    }
    .col-xl-7 {
        width: 58.33333333%;
    }
    .col-xl-6 {
        width: 50%;
    }
    .col-xl-5 {
        width: 41.66666667%;
    }
    .col-xl-4 {
        width: 33.33333333%;
    }
    .col-xl-3 {
        width: 25%;
    }
    .col-xl-2 {
        width: 16.66666667%;
    }
    .col-xl-1 {
        width: 8.33333333%;
    }

    .col-xl-offset-12 {
        margin-left: 100%;
    }
    .col-xl-offset-11 {
        margin-left: 91.66666667%;
    }
    .col-xl-offset-10 {
        margin-left: 83.33333333%;
    }
    .col-xl-offset-9 {
        margin-left: 75%;
    }
    .col-xl-offset-8 {
        margin-left: 66.66666667%;
    }
    .col-xl-offset-7 {
        margin-left: 58.33333333%;
    }
    .col-xl-offset-6 {
        margin-left: 50%;
    }
    .col-xl-offset-5 {
        margin-left: 41.66666667%;
    }
    .col-xl-offset-4 {
        margin-left: 33.33333333%;
    }
    .col-xl-offset-3 {
        margin-left: 25%;
    }
    .col-xl-offset-2 {
        margin-left: 16.66666667%;
    }
    .col-xl-offset-1 {
        margin-left: 8.33333333%;
    }
    .col-xl-offset-0 {
        margin-left: 0;
    }

    .col-xl-push-1 {
        left: 100% * 1 / 12;
    }
    .col-xl-push-2 {
        left: 100% * 2 / 12;
    }
    .col-xl-push-3 {
        left: 100% * 3 / 12;
    }
    .col-xl-push-4 {
        left: 100% * 4 / 12;
    }
    .col-xl-push-5 {
        left: 100% * 5 / 12;
    }
    .col-xl-push-6 {
        left: 100% * 6 / 12;
    }
    .col-xl-push-7 {
        left: 100% * 7 / 12;
    }
    .col-xl-push-8 {
        left: 100% * 8 / 12;
    }
    .col-xl-push-9 {
        left: 100% * 9 / 12;
    }
    .col-xl-push-10 {
        left: 100% * 10 / 12;
    }
    .col-xl-push-11 {
        left: 100% * 11 / 12;
    }

    .col-xl-pull-1 {
        right: 100% * 1 / 12;
    }
    .col-xl-pull-2 {
        right: 100% * 2 / 12;
    }
    .col-xl-pull-3 {
        right: 100% * 3 / 12;
    }
    .col-xl-pull-4 {
        right: 100% * 4 / 12;
    }
    .col-xl-pull-5 {
        right: 100% * 5 / 12;
    }
    .col-xl-pull-6 {
        right: 100% * 6 / 12;
    }
    .col-xl-pull-7 {
        right: 100% * 7 / 12;
    }
    .col-xl-pull-8 {
        right: 100% * 8 / 12;
    }
    .col-xl-pull-9 {
        right: 100% * 9 / 12;
    }
    .col-xl-pull-10 {
        right: 100% * 10 / 12;
    }
    .col-xl-pull-11 {
        right: 100% * 11 / 12;
    }

    .hidden-xl {
        display: none !important;
    }
}
