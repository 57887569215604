@import url(base.less);

@white: #fff;
@black: #253135;
@creme: #f8f4ec;
@grey: #686d73;
@green: #24c196;
@green-dark: #1fa07d;
@red: #fc3e41;
@blue: #020818;

@sans-serif: "myriad-pro-condensed", sans-serif;
@serif: "adobe-text-pro", serif;
@icons: "NiceToMeat";

// Kleuren
.creme {
    background-color: @creme;

    .title span {
        background: @creme;
    }
}

.white {
    color: @white;
}

.black {
    color: @black;
}

.red {
    color: @red;
}

.green {
    color: @green;
}

html {
    font-size: 9px;
}

body {
    font-size: 2.1rem;
    font-weight: 300;
    font-family: @serif;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {
    font-family: @sans-serif;
    color: @black;
    font-weight: 700;
}

h1, .h1 {
    font-size: 6rem;
    position: relative;
    color: @red;
}

h2, .h2 {
    position: relative;
    font-size: 3.4rem;
    color: @grey;

    &.title {

        span {
            position: relative;
            color: @red;
            padding: 0 1.5rem;
            background-color: @white;
            z-index: 1;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 80%;
            right: 0;
            height: 2px;
            background-color: fade(@grey, 55%);
        }
    }

    span {
        color: @black;
    }
}

h3, .h3 {
    font-size: 3.2rem;
    line-height: 1;
    margin-top: 0;
    margin-bottom: 3rem;

    span {
        font-size: 3.2rem;
        color: @white
    }
}

img {
    max-width: 100%;
    height: auto;
}

.img-full-width {
    margin-left: -15px;
    margin-right: -15px;
}

.img-border {
    border: 1px solid fade(@blue, 10px);
}

.img-object-fit {
    height: 100%;
    width: 100%;
}

a {
    color: @red;

    &:hover, &:focus, &:active, &.active {
        color: @red;
        text-decoration: underline;
        outline: none;
    }
}

p {
    &.small {
        font-size: 75%;
    }
}

.lead {
    font-weight: 700;
    margin-top: 20px;
}

.date, .time {
    font-family: @sans-serif;
}

// Buttons
.btn {
    position: relative;
    font-family: @sans-serif;
    font-size: 2.1rem;
    font-weight: 700;
    border-radius: 35px;

    &.active.focus, &.active:focus, &.active:hover, &:active.focus, &:active:focus, &:active:hover,
    &:hover, &:focus, &:active, &.active {
        box-shadow: none;
        outline: 0;
    }

    &:not(.dropdown-toggle) {
        text-transform: uppercase;
    }

    .input-group-btn:last-child > & {
        font-size: 2.4rem;
        color: @grey;
        line-height: inherit;
        padding: 0 0 0 .35rem;
        border: 0;
        background: transparent;
        transition: color 200ms;

        &:hover {
            color: @green;
            background: transparent;
        }

        .wb-search {
            font-size: 1.8rem;
            bottom: 2px;
        }

        .wb-circle {
            font-size: 5.2rem;
            bottom: 0;
        }
    }
}

.btn-default, .btn-primary {
    border: 2px solid @black;
    background: transparent;
    color: @black;
    padding: 0 45px 0 30px;
    height: 5rem;
    line-height: 2.1;
    transition: border 200ms;

    .wb-events-icons-ready &:after {
        content: '\f01a';
        font-family: @icons;
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translateY(-50%);
        transition: right 200ms;
        color: @green;
    }

    &.active.focus, &.active:focus, &.active:hover, &:active.focus, &:active:focus, &:active:hover,
    &:hover, &:focus, &:active, &.active {
        color: @black;
        border: 2px solid @green;
        background: transparent;
        text-decoration: none;

        &:after {
            right: 15px;
        }
    }

    &.btn-white {

        &:after {
            color: @white;
        }

        &.active.focus, &.active:focus, &.active:hover, &:active.focus, &:active:focus, &:active:hover,
        &:hover, &:focus, &:active, &.active {
            border-color: @white;
        }
    }

    &.btn-classic, &.btn-select, &.btn-gold {
        padding: 20px;

        &:after {
            content: '';
            background-image: url('/theme/img/label-classic.png');
            background-size: contain;
            background-repeat: no-repeat;
            width: 36px;
            height: 30px;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &.btn-select:after {
        background-image: url('/theme/img/label-select.png');
    }
    &.btn-gold:after {
        background-image: url('/theme/img/label-gold.png');
    }
}

.btn-primary {
    color: @white;
    background-color: @green;
    border: 2px solid @green;
    transition: background 200ms, opacity 200ms;

    .wb-events-icons-ready &:after {
        opacity: 0;
        right: 0;
        transition: right 200ms, opacity 200ms;
        color: @black;
    }

    &.active.focus, &.active:focus, &.active:hover, &:active.focus, &:active:focus, &:active:hover,
    &:hover, &:focus, &:active, &.active {
        color: @white;
        background: @green;
        border: 2px solid @green;

        &:after {
            opacity: 1;
            right: 10px;
        }
    }
}

.btn-link, .btn-prev {
    font-size: 2.1rem;
    font-weight: 700;
    color: @red;
    text-transform: uppercase;
    padding: 1rem 1.5rem 1rem 0;
    transition: color 200ms;

    .wb-events-icons-ready &:after {
        content: '\f01a';
        position: absolute;
        top: 52%;
        right: -10px;
        font-size: 2.6rem;
        font-family: @icons;
        color: @red;
        transform: translateY(-50%);
        transition: right 200ms, color 200ms;
    }

    &:hover, &:focus, &:active, &:active:focus {
        color: @green;
        text-decoration: none;

        .wb-events-icons-ready &:after {
            right: -15px;
            color: @green;
        }
    }
}

.btn-prev {
    padding: 1rem 0 1rem 1.5rem;

    .wb-events-icons-ready &:after {
        content: '\f019';
        left: -10px;
        right: inherit;
        transition: left 200ms, color 200ms;
    }
    &:hover, &:focus, &:active, &:active:focus {

        .wb-events-icons-ready &:after {
            left: -15px;
            right: inherit;
        }
    }
}

.btn-group-sm > .btn,
.btn-sm {
    font-size: 1.2rem;
}

[data-href] {
    cursor: pointer;
}

.open > .dropdown-toggle.btn-default{

    &:hover, &:focus, &:active, &:active:focus{
        color: @black;
        border: 2px solid @green;
        background: transparent;
        text-decoration: none;
    }
}

//Modal

.modal-backdrop{
    background-color: @white;
}
.modal-backdrop.in{
    opacity: .8;
}
.modal-content{
    box-shadow: none;
    border: 2px solid #c3c4c6;
}
.modal-header, .modal-body {
    padding: 25px;
}
.modal-header{
    padding-bottom: 0;
    border: none;

    .modal-title{
        color: @red;
        font-size: 2.8rem;
        line-height: 1;
    }
}


// Slickslider
.slider-nav {
    .slick-slide {
        picture {
            transition: opacity 300ms ease-in-out;

            &:last-child {
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                right: 0;
            }
        }

        .when-active {
            opacity: 0;
        }

        &.slick-current {
            .when-active {
                opacity: 1;
            }
        }
    }
}

.slick-nav-wrap {

    li {
        .wb {
            color: @grey;
            padding: 0 .5rem;
            opacity: .5;
            transition: opacity 200ms;
            cursor: pointer;

            &:hover, &:focus, &:active, &:active:focus {
                opacity: 1;
            }
        }
    }
}

.slides {
    opacity: 0;
}

//Radio & Checkbox
[type="radio"], [type="checkbox"] {
    border: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

input[type=radio] + span:before, input[type=checkbox] + span:before {
    content: '';
    display: inline-block;
    border-radius: 50%;
    border: 2px solid @black;
    transition: 0.5s ease all;
    height: 15px;
    width: 15px;
    margin-left: -2rem;
    margin-right: 1rem;
    cursor: pointer;
}

[type="radio"]:checked + span:before, [type="checkbox"]:checked + span:before {
    background: @green;
}

input[type=checkbox] + span:before {
    border-radius: 0;
    border: 2px solid @black;
}

// Forms
form {

    span {
        color: @red;
    }
}

.form-control {
    font-family: @serif;
    font-size: 2.1rem;
    padding: 6px 20px 7px;
    border: none;
    border-radius: 35px;
    background: @white;
    height: 5rem;
    box-shadow: none;

    &::-webkit-input-placeholder {
        color: @grey;
        font-size: 1.8rem;
    }

    &:focus {
        box-shadow: none;
        outline: 0;
        border-color: @green;
    }

    .input-group &:first-child {
        border-radius: 28px;
    }

    .form-product &, .form-job & {
        border: 2px solid @grey;

        &::-webkit-input-placeholder {
            color: @grey;
        }
    }

    .search & {
        border: 2px solid fade(@grey, 50%);
    }

}

.has-error .form-group, .has-error .form-control {
    border-color: @red;
    background: @white;

}

.has-error .help-block {
    color: @red;
    font-size: 14px;
}

textarea.form-control {
    height: 180px;
    border-radius: 20px;
}

.form-contact {
    position: relative;
    margin-top: 9rem;
    margin-bottom: 5rem;

    &:before {
        content: '';
        background: @red;
        position: absolute;
        top: -45px;
        left: 0;
        width: 40px;
        height: 4px;
        border-radius: 3px;
    }

    &.form-horizontal {
        .control-label {
            text-align: left;
            font-weight: 400;
        }

    }
    hr {
        border-color: @white;
    }
    ul li {
        display: inline-block;
    }
}

// Dropzone
.dropzone {
    position: relative;
    text-align: center;
    padding: 3.5rem 2rem;
    margin: 1rem 0 2rem;
    background: transparent;
    border: 3px dashed fade(@grey, 25%);
    border-radius: 25px;
    bottom: 100%;
    left: 0;
    right: 0;

    .dz-message {
        margin: 0;
    }
    .dz-preview .dz-progress {
        top: auto;
        bottom: 10%;

        .progress-bar {
            background-color: black;
        }
    }

    p {
        margin-bottom: 10px !important;
    }

    .btn-link {
        padding: 0 1.5rem 0;
    }

    &.has-error{
        border: 3px dashed @red;
    }
}

.dz-remove {
    position: absolute;
    z-index: 21;
    top: -3px;
    right: -3px;
}

// Alerts
.alert {
    color: @white;
    text-align: center;
    border-color: @creme;
}

.alert-info {
    background: @green;
    border: transparent;
}

.alert-danger {
    background: @red;
}

.alert-env {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: @black;
    padding: 5px 15px;
    border-color: @black;
}

// Dropdown
.header-content .dropdown-toggle {
    padding: 5px 10px;
    color: @grey;
    line-height: inherit;

    i {
        color: @green;
        font-size: 1.3rem;
        transform: translateY(-2px);
    }

    span {
        margin-right: .5rem;
    }
}

.btn-group {

    &.open {
        .dropdown-toggle {
            background: @white;
            box-shadow: none;
        }
    }
}

.dropdown-menu {
    font-family: @sans-serif;
    font-size: 18px;
    border: 2px solid @black;
    border-radius: 20px;
    padding: 0;
    overflow: hidden;
    top: 60px;
    left: 10px;
    min-width: 120px;

    li a {
        position: relative;
        padding-left: 4rem;
        background: @white;

        &:before {
            content: '\f01a';
            font-family: @icons;
            color: @black;
            position: absolute;
            top: 50%;
            left: 15px;
            transform: translateY(-50%);
            transition: color 200ms;
        }

        &:hover {
            background: @white;

            &:before {
                color: @green;
            }
        }
    }
}

// Sections
section {
    position: relative;
    padding: 5rem 0;

    .wb-events-icons-ready &:not(.page-intro):not(.standaard):not(.agenda):not(.overlay-choose):not(.overlay-popup):not(.section-first):not(.form):after {
        font-family: @icons;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '\f020';
        color: @grey;
        text-align: center;
        line-height: 3;
        background: @white;
        border-radius: 50%;
        width: 6rem;
        height: 6rem;
        z-index: 4;
    }

    h2 {
        margin: 0 0 3rem;
    }

    &.standaard{

        h2{
            margin: 3rem 0 1rem;
        }
    }
}

.section-header {
    position: relative;
    margin-bottom: 2rem;

    .btn {
        position: absolute;
        right: 0;
        bottom: 0;

        &.btn-prev {
            left: 0;
            right: inherit;
        }
    }
}

.section-first {
    padding: 2rem 0 10rem;
}

.parallax-wrap{
    width: 100%;
    height: 170px;
    position: relative;
    overflow: hidden;

    img{
        width: 100vmax;
        z-index: -1;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        pointer-events: none
    }
}

// Main
main {
    margin-top: 60px;
}

// Header
header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 60px;
    background: @white;
    //overflow: hidden;
    z-index: 5;
    border-bottom: 2px solid fade(@grey, 20%);
}

.logo {
    display: inline-block;
    padding-top: 1.2rem;
}

.header-content {
    display: flex;
    justify-content: flex-end;

    .btn-default:after {
        content: none;
    }
    .form-control {
        border: 2px solid @grey;
    }

    > div {

        &:not(:last-child) {
            padding: 7px 10px 0;
        }

        &:last-child {
            margin-right: -15px;
        }
    }
}

// Nav
.menu-trigger {
    background: @red;
    padding: 12px;
    margin-right: -15px;
    transition: all 300ms ease-in-out;
    cursor: pointer;

    &:hover, &:focus, &:active, &:active:focus {
        background: darken(@red, 10%);
    }

    .hamburger {
        width: 34px;
        height: 34px;
        position: relative;
        transform: rotate(0deg);
        transition: .5s ease-in-out;

        span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background: @white;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
            transition: .25s ease-in-out;

            &:nth-child(1) {
                top: 0;
            }
            &:nth-child(2), &:nth-child(3) {
                top: 12px;
            }
            &:nth-child(4) {
                top: 24px;
            }
        }
    }
    &.open {

        .hamburger span {

            &:nth-child(1) {
                top: 12px;
                width: 0;
                left: 50%;
            }
            &:nth-child(2) {
                transform: rotate(45deg);
            }
            &:nth-child(3) {
                transform: rotate(-45deg);
            }
            &:nth-child(4) {
                top: 12px;
                width: 0;
                left: 50%;
            }
        }
    }
}

.switch {
    color: @white;
    font-family: @sans-serif;
    font-weight: 700;
    padding: 10px 0;
    border: 1px solid fade(@white, 10%);

    form {
        margin: 0;
    }
}

.switch-toggle {
    display: inline-block;
    background: darken(@black, 5%);
    border-radius: 35px;
    margin: 0 15px;
    padding: 5px;
    box-shadow: inset 0 0 10px @blue;

    input {
        position: absolute;

        &:checked + label {
            color: @white;
            background-color: #1fc470;
        }
    }

    label {
        display: inline-block;
        color: fade(@white, 50%);
        text-align: center;
        border-radius: 35px;
        padding: 2px 14px;
        margin: 0;
        transition: all 0.1s ease-in-out;

        &:hover {
            color: @white;
            cursor: pointer
        }
    }
}

.menu-wrapper {
    display: none;
    position: fixed;
    z-index: 6;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background: @black;
    overflow: auto;

    &:after {
        content: '';
        background: url('/theme/img/beeldmerk.png') center no-repeat;
        background-size: contain;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translate(-50%, -50%);
        z-index: -1;
    }

    .main-menu {
        position: relative;
        padding: 0 0 6rem 0;
        margin: 0;
        overflow: auto;
        height: ~"calc(100vh - 60px)";
        border-top: 1px solid fade(@white, 10%);

        &:before {
            content: '';
            background: @red;
            position: absolute;
            top: -10rem;
            left: 50%;
            width: 40px;
            height: 4px;
            border-radius: 3px;
            transform: translateX(-50%);
        }

        li {
            transition: background 200ms;

            &:not(:last-child) {
                border-bottom: 1px solid fade(@white, 10%);
            }

            a {
                display: block;
                font-family: @sans-serif;
                font-size: 2.1rem;
                font-weight: 700;
                letter-spacing: 5px;
                color: @white;
                padding: 1.8rem 0;
                text-transform: uppercase;
                cursor: pointer;

                &:hover, &:focus, &:active, &:active:focus, &.active {
                    color: @red;
                    text-decoration: none;
                }
            }
            &.active {
                color: @red;
            }
            ul {
                transform: translateY(-25px);
                padding: 5px 0 0 0;

                li {
                    display: inline-block;

                    &:not(:last-child) {
                        border-bottom: none;
                    }

                    &:not(:last-child) {
                        border-right: 2px solid @red;
                    }

                    a {
                        color: fade(@white, 90%);
                        font-size: 1.6rem;
                        padding: 0 .8rem;
                        line-height: 1;
                        transition: color 200ms;
                        letter-spacing: 2px;

                        &:hover, &:focus, &:active, &:active:focus, &.active {
                            background: transparent;
                            color: @red;
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }
}

.overlay-choose {
    display: none;
    background: fade(@white, 95%);
    position: fixed;
    z-index: 6;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    padding: 2rem 0 0 0;
}

// Overlay popup
.overlay-popup {
    display: none;
    position: fixed;
    right: 10px;
    bottom: 0;
    min-height: 30vh;
    width: 300px;
    padding: 15px 25px;
    border: 4px solid @green;
    border-bottom: 0;
    background: @white;
    z-index: 6;

    img{
        border: 3px solid fade(@grey, 30%);
    }

    .btn-close{
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 0;
        background: transparent;

        .wb{
            color: @red;
            font-size: 2.8rem;
        }
    }

    small{
        display: block;
        font-size: 1.4rem;
        font-family: @sans-serif;
        font-weight: 700;
        color: fade(@black, 50%);
        text-transform: uppercase;
    }

    h2{
        font-size: 2.1rem;
        color: @black;
        margin-bottom: 1.5rem;
    }

    p{
        font-size: 1.8rem;
        line-height: 1.9rem;
        font-family: @sans-serif;
        margin-bottom: 1.5rem;
    }
}

// Intro
.page-intro {
    color: @white;
    padding: 0;

    .container-fluid {
        margin: 0;
        padding: 0;
    }

    h1 {
        color: @white;
        margin: 2rem 0;
    }

    .intro {
        //height: 600px;
    }

    .intro-text {
        position: relative;
        background: url('/theme/img/intro-image.png') @grey center no-repeat;
        background-size: contain;
        height: 400px;
        z-index: 1;
        background-color: fade(@grey, 55%);

        &:after {
            content: '';
            background: fade(@black, 90%);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 1;
        }
        > div {
            position: absolute;
            top: 50%;
            left: 15px;
            right: 15px;
            transform: translateY(-50%);
            z-index: 2;

            &:before {
                content: '';
                background: @red;
                position: absolute;
                top: 0;
                left: 50%;
                width: 40px;
                height: 4px;
                border-radius: 3px;
                z-index: 2;
                transform: translateX(-50%);
            }
        }

        .btn-default {
            color: @white;
            border-color: fade(@white, 20%);
            margin-top: 1rem;

            &:hover, &:focus, &:active, &:active:focus {
                border-color: @white;
            }
        }
    }

}

.item-video {
    height: 400px;
    cursor: pointer;
    overflow: hidden;

    > div {
        position: relative;
        height: 100%;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: fade(@black, 40%);
            opacity: 1;
            transition: opacity 200ms ease-in-out;
        }

        img {
            position: absolute;
            top: 0;
            left: 50%;
            right: 0;
            height: 100%;
            display: block;
            width: auto;
            max-width: none;
            transform: translateX(-50%);
        }
    }
    .item-meta {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 0;
        left: 0;
        color: @white;
        padding: 0 15px;
        transform: translateY(-50%);
    }

    &:hover {
        > div:after {
            opacity: 0.2;
        }
    }

    .wb-stack {
        font-size: 60px;

        .wb-circle {
            color: fade(@white, 20%);
        }

        .wb-play {
            position: absolute;
            top: 51%;
            font-size: 80px;
            transform: translateY(-50%);
        }
    }
}

// Items
.item {
    position: relative;
    margin-bottom: 2rem;

    &:hover, &:focus, &:active, &:active:focus {

        .product-photo {
            transform: translateY(-30px);
            filter: drop-shadow(0 46px 7px #acacac);
        }
        .btn-default {
            color: @black;
            border: 2px solid @green;

            &:after {
                right: 15px;
            }
        }
    }

    .product-photo {
        filter: drop-shadow(0 16px 7px #acacac);
        transition: filter 200ms, transform 200ms;
        margin-bottom: 5rem;
    }
}

.item-content {
    margin-bottom: 2.5rem;

    h2 {
        margin-top: 0;
        margin-bottom: 5rem;
    }

    h3 {
        color: @red;
        margin-top: 4rem;
        margin-bottom: .5rem;
    }

    ul {
        list-style: none;
        padding: 0 0 0 20px;

        li {
            position: relative;
            display: block;
            padding-left: 20px;

            &:before {
                content: '\f026';
                font-family: @icons;
                color: @red;
                font-size: 1rem;
                position: absolute;
                top: 8px;
                left: 0;
            }
        }
    }

    .meta {
        border-top: 1px solid fade(@grey, 40%);
        border-bottom: 2px solid fade(@grey, 40%);
        margin-top: 4rem;
        margin-bottom: 4rem;
        padding: 20px 0;

        > div > div {

            &:not(:last-child) {
                border-right: 1px solid fade(@grey, 40%);
            }

            h3 {
                margin-top: 0;
            }
        }
    }
}

.item-agenda {

    .date, .time {
        margin-left: 60px;
    }
    .date {
        color: @red;
    }
    .time {

        font-size: 1.8rem;
        margin-bottom: 10px;
    }

    .item-agenda-content {
        position: relative;
        font-size: 1.6rem;
        line-height: 1.2;
        padding: 2.5rem;
        margin-left: 30px;
        border: 2px solid @grey;
        border-radius: 35px;

        h3 {
            color: @grey;
            font-size: 1.8rem;
            font-weight: 700;
            margin: 0 0 1rem;
        }

        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: -40px;
            height: 23px;
            width: 23px;
            background: @white;
            border: 3px solid @red;
            border-radius: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }

        &:after {
            top: -50px;
            left: -30px;
            width: 3px;
            height: 100vh;
            background: @red;
            border: 0;
            border-radius: 0;
            transform: inherit;
            z-index: 1;
        }

        p:last-child{
            margin: 0;
        }
    }
}

.item-nieuwsbericht, .item-vacature {
    background: @creme;
    padding: 15px 15px 30px;
    border: 5px solid @creme;
    transition: border 200ms;
    cursor: pointer;

    &:hover, &:focus, &:active, &:active:focus {
        border: 5px solid fade(@black, 5%);

        .btn-default {
            color: @black;
            border: 2px solid @green;

            &:after {
                right: 15px;
            }
        }
    }

    h3 {
        overflow: hidden;
    }
}

.item-user {

    img {
        border: 10px solid @white;
        box-shadow: 0 0 1px fade(@black, 75%);
        margin-bottom: 2rem;
    }

    p {
        font-size: 1.8rem;
        font-family: @sans-serif;
        text-transform: uppercase;
        line-height: 1.1;
        margin-bottom: 0;

        span {
            display: block;
            color: fade(@black, 50%);
            text-transform: none;
        }
    }
}

.index-overview, .item-product {

    h3 {
        text-transform: uppercase;
        margin-bottom: .5rem;
    }
    p {
        margin-bottom: 2rem;
    }
}

.item-product {
    margin-bottom: 4rem;
    padding-bottom: 3rem;
    font-size: 1.8rem;
    border-bottom: 1px solid fade(@grey, 50%);
    text-align: center;

    picture {
        height: 12rem;
        display: block;
    }
}

.item-search {
    border: 2px solid fade(@grey, 20%);
    padding: 15px 30px 15px 15px;
    cursor: pointer;

    &:hover {

        .btn-link {
            color: @green;
            text-decoration: none;

            &:after {
                right: -15px;
                color: @green;
            }
        }
    }

    h3 {
        margin-top: 1rem;
    }
}

.item-overlay {
    padding: 30px 15px;
    background: @white;
    border-radius: 25px;
    border: 1px solid fade(@grey, 50%);
    margin-top: 3rem;
    cursor: pointer;

    h2 {
        font-size: 6rem;
        margin: 0 0 3rem;
        line-height: 1;
        color: @blue;

        span {
            display: block;
            color: fade(@blue, 40%);
            font-size: 3rem;
        }
    }

    p {
        margin-bottom: 3rem;
    }
}

.item-media {
    cursor: pointer;
    overflow: hidden;

    .item-media-img {
        position: relative;
        overflow: hidden;

        .item-meta {
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            color: @white;
            transform: translate(-50%, -50%);
        }
    }
    &:hover{

        .wb-stack {

            .wb-circle {
                color: fade(@white, 40%);
            }

            .wb-play {
                font-size: 8.3rem;
                top: 2%;
            }
        }

        img{
            transform: scale(1.1);
        }
    }

    img{
        transition: transform 200ms;
    }

    h3 {
        margin: 1rem 0 0;
    }

    .wb-stack {
        font-size: 6rem;

        .wb-circle {
            color: fade(@white, 20%);
            transition: color 200ms;
        }

        .wb-play {
            font-size: 8.3rem;
            top: 2%;
        }
    }
}

.filter {
    margin-bottom: 5rem;

    li {
        display: inline-block;

        .btn-default {
            border-color: fade(@black, 30%);
            transition: border 200ms, background 200ms;
            transform: translateY(0);

            &.active {
                border-color: @green;
            }

            &:hover, &:active, &:focus, &:active:focus, &.active {
                border-color: @green;
                background: fade(@green, 10%);
            }
        }
    }
}

.producten-show, .en-producten-show, .partners-show, .en-partners-show{

    .standaard{
        position: relative;

        .product-logo, .partner-logo{
            position: absolute;
            top: -35px;
            right: 5%;
            background: @white;
            padding: 10px;
        }
    }

    h2, .h2 {

        &.title {

            &:after {
                top: 130%;
            }
        }
    }
}

// Featured
.featured {

    .image-line {
        position: relative;
        margin-bottom: 0;

        &:after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            bottom: 50%;
            right: 0;
            height: 2px;
            background-color: fade(@grey, 30%);
            transform: translateY(-50%);
        }

        li {
            display: inline-block;
            position: relative;
            padding: 0 .7rem;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                z-index: 1;
                top: 50%;
                left: 50%;
                border-radius: 50%;
                border: 2px solid fade(@grey, 30%);
                background: @white;
                width: 80px;
                height: 80px;
                transform: translate(-50%, -50%);
            }

            &:hover {

                img {
                    transform: translateY(-10px);
                }
            }

            img {
                display: inline-block;
                position: relative;
                padding: 33px 0;
                filter: drop-shadow(0 4px 4px #acacac);
                transition: filter 200ms, transform 200ms;
                z-index: 2;
            }
        }
    }

    .featured-information {

        h2 {
            margin: 0 0 2rem;
        }

        a {
            margin-top: 1.5rem;

            &:hover, &:focus, &:active:focus {
                color: @grey;
                text-decoration: none;
            }
        }
        .btn-default{
            padding: 0 25px 0 20px;

            &:after{
                right: 5px;
            }
        }
    }
}

.featured-detail {
    background: @black;
    color: @white;

    h2, .h2 {

        &.title {
            color: @white;

            span {
                background-color: @black;
            }

            &:after {
                content: '';
                position: absolute;
                background-color: @white;
            }
        }
    }
}

.slick-slide {

    img {
        filter: drop-shadow(0 10px 10px #acacac);
        padding-left: 15px;
    }
}

.product-content {
    margin-top: 2rem;

    img{
        margin-bottom: 2rem;
    }

    &:not(:last-child){

        .product-text {
            border-bottom: 1px solid fade(@grey, 50%);
            padding-bottom: 2rem;
            margin-bottom: 2rem;
        }
    }
}

// Agenda
.agenda {
    padding-top: 0;
    padding-bottom: 5rem;
    overflow: hidden;

    ul {
        list-style: none;
        border-right: 3px solid @red;
        padding-right: 2.3rem;

        li {
            color: @red;
            font-family: @sans-serif;
        }

        p {
            color: @black;
        }
    }
}

.index-overview {
    overflow: hidden;
    picture {
        margin-left: -15px;
    }
}

// News
.news-index {
    z-index: 3;

    a:hover, &:active, &:active:focus {
        color: @black;
        text-decoration: none;
    }

    p {
        margin: 2rem 0 1.5rem 0;
    }
}

// Chefkok
.chefkok {

    h2 {
        line-height: 1;
        margin: 0 0 1.2rem;
    }

    h3 {
        margin: 0 0 3rem;
    }

    .item-media {

        .wb-stack {
            font-size: 4rem;

            .wb-play {
                font-size: 5.3rem;
            }
        }

        &:nth-child(n+3){
            display: none;
        }
    }
}

// Geschiedenis
.timeline {
    background: @creme;
    position: relative;

    > div {
        position: relative;

        &:before, &:after {
            content: '';
            position: absolute;
            top: 8rem;
            left: 0;
            width: 10rem;
            height: 30rem;
            background-image: url('/theme/img/fade-left.png');
            background-size: contain;
            background-repeat: repeat-y;
            z-index: 2;
        }
        &:after {
            background-image: url('/theme/img/fade-right.png');
            left: inherit;
            right: 0;
        }
    }

    .title span {
        background: @creme;
    }

    .item-timeline {
        position: relative;
        font-size: 1.8rem;
        font-style: italic;

        &:after {
            content: '';
            position: absolute;
            top: 10.5rem;
            left: 0;
            right: 0;
            background-image: url('/theme/img/dottedline.png');
            background-size: contain;
            background-repeat: repeat-x;
            height: 22px;
            width: 100%;
        }

        h2 {
            margin: 0 0 6rem;

            span {
                display: inline-block;
                font-size: 2.8rem;
                font-style: normal;
                color: @black;
                background: @white;
                line-height: 2.5;
                border-radius: 50%;
                height: 9rem;
                width: 9rem;
                box-shadow: 0 0 1px fade(@black, 50%);
                border: 10px solid @creme;
            }
        }

    }
    ul li {
        display: inline-block;

        .wb {
            font-size: 5rem;
        }
    }
}

.slider-history {
    .slides {
        display: flex;
        opacity: 0;
        transition: opacity ease-out 400ms;

        &.slick-initialized {
            opacity: 1;
        }

        > div {
            width: 100%;
        }

        .slick-slide {
            height: auto;
            padding: 0 2rem;
        }
    }
}

//questions
.form-job{

    .question-header, .question{
        font-family: @sans-serif;
        font-size: 2.1rem;
        color: @white;
        background: @green;
        border-radius: 35px;
        padding: 5px 3rem;
        margin-bottom: 15px;
    }

    .question{
        font-family: @serif;
        font-size: 1.6rem;
        color: @black;
        background: fade(@black, 10%);
        padding: 5px 2rem 5px 3rem;
        margin-bottom: 0;

        .radio-inline{
            padding-left: 10px;
        }
        .radio-inline+.radio-inline {
            margin-left: 5px;
        }
    }

    .form-group-enquete{
        margin-bottom: 5px;
    }
}

// Instagram
.instagram {

    hr {
        border: none;
        background-image: linear-gradient(to right, fade(@black, 25%) 75%, @white 0);
        background-position: bottom;
        background-size: 20px 2px;
        background-repeat: repeat-x;
        height: 2px;
    }
    ul {
        position: relative;
        padding: 3rem 0;

        &:before, &:after {
            content: '';
            background: @red;
            position: absolute;
            top: 0;
            left: 50%;
            width: 40px;
            height: 4px;
            border-radius: 3px;
            transform: translateX(-50%);
        }
        &:after {
            content: '';
            background: @red;
            top: inherit;
            bottom: 0;
        }

        &.slick-nav-wrap {
            padding: 1rem 0;

            li {
                padding: 0;

                .wb {
                    font-size: 3rem;
                    color: @grey;
                }
            }

            &:before, &:after {
                content: none;
            }
        }

        li {
            display: inline-block;
            padding: 0 0.5rem;
            transition: color 200ms;

            .wb {
                font-size: 4.6rem;
            }

            &:hover, &:active, &:focus, &:active:focus{

                .wb{
                    color: darken(@red, 10%);
                }
            }
        }
    }

    .slider-instagram {

        .item-instagram {
            margin-bottom: 0;
        }

        .slick-slide img {
            filter: none;
            height: 250px;
            width: 100%;
            object-fit: cover;
            padding: 0;
        }
    }
}

// Contact
.contact {
    background: @creme;

    h2 a{
        color: @grey;
        text-decoration: underline;
        transition: color 200ms;

        &:hover, &:focus, &:active, &:active:focus{
            color: @red;
        }
    }

    .col-chefs {
        background: @white;
    }

    .main-img {
        padding: 0;
    }
}

// Footer
footer {
    color: @white;

    h3 {
        font-size: 2.8rem;
        margin-bottom: 15px;
    }

    hr {
        border-color: fade(@white, 20%)
    }
    section {
        padding: 0;

        &:after {
            content: none !important;
        }
    }

    .footer-content {
        padding: 5rem 0;
        background: fade(@grey, 40%);
        color: @black;
    }
    .footer-info {
        font-size: 1.6rem;
        background: @blue;
        padding: 3rem 0;

        .wb {
            color: @red;
            padding-right: 15px;
        }
    }
    .footer-naw{
        a{
            color: @white;
            text-decoration: underline;
            transition: color 200ms;

            &:hover{
                color: @red;
            }
        }
    }
    .footer-sub {
        font-size: 1.6rem;
        background: @red;
        line-height: 3;
        padding: 3rem 0 0;

        p {
            margin: 0;
        }

        ul {
            display: inline-block;
            background: #c3c5c7;
            margin: 0;

            li {
                display: inline-block;

                a {
                    display: inline-block;
                    color: @black;
                    padding: 0 1.8rem;
                    background: #c3c5c7;
                    transition: background 200ms, color 200ms;

                    &:hover, &:focus, &:active, &:active:focus {
                        color: @red;
                        background: @white;
                    }
                }
            }

            &.footer-links {
                background: transparent;

                li {
                    display: block;
                    line-height: 1.6;

                    a {
                        color: @white;
                        text-decoration: underline;
                        background: transparent;
                        padding: 0;
                        line-height: 1.6;

                        &:hover, &:focus, &:active, &:active:focus {
                            color: @blue;
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 480px) {

    .producten-show, .en-producten-show, .partners-show, .en-partners-show{

        h2, .h2 {

            &.title {

                &:after {
                    top: 80%;
                }
            }
        }
    }

    .item-product {
        border: none;

        h3 {
            .line-clamp(6rem, 2);
        }
    }

    .product-content, .featured-detail {
        margin-top: 0;
    }

    .chefkok .item-media:nth-child(-n+3){
        display: block;
    }
}

@media (min-width: 768px) {

    html {
        font-size: 8px;
    }
    html, body {
        height: 100%;
        width: 100%;
    }

    h2, .h2 {
        font-size: 5rem;
    }

    h3, .h3 {

        span {
            font-size: 3.2rem;
            color: @white
        }
    }

    .btn-default {

        &.btn-classic, &.btn-select, &.btn-gold {
            padding: 0 20px 0 55px;

            &:after {
                content: '';
                left: 10px;
                transform: translate(0, -50%);
            }
        }
    }

    .btn-default, .btn-primary{
        padding: 0 45px 1px 30px;
    }

    .btn-link, .btn-prev {
        font-size: 1.8rem;
    }

    .modal-dialog-home{
        width: 75%;
    }

    section {
        padding: 8rem 0;

        h2 {
            margin-bottom: 6rem;
        }
    }

    .section-header {
        margin-bottom: 4rem;
    }

    .parallax-wrap{
        height: 300px;
    }

    .form-job{

        .question-header, .question{
            padding: .6rem 3rem 1rem;
        }

        .question{
            padding: 1rem 2rem .5rem 3rem;
            margin-bottom: 0;

            .radio-inline{
                padding-left: 20px;
            }

            .radio-inline+.radio-inline {
                margin-left: 5px;
            }
        }
    }

    main {
        margin-top: 80px;
        min-height: 500px;
    }

    header {
        height: 80px;
    }

    .header-content {

        .btn-default:after {
            content: none;
        }
        .form-control {
            border: 2px solid @grey;
        }

        > div {

            &:not(:last-child) {
                padding: 20px 10px 0;
                border-right: 2px solid fade(@grey, 20%);
            }

            &.search{
                width: 300px;
            }

            &.primary-class {
                border: none;

                .btn-primary {
                    padding: 0 30px;
                }
            }
        }

        .switch{
            color: @black;
        }

        .switch-toggle {
            display: inline-block;
            background: @green;
            border-radius: 35px;
            margin: 3px 0 0 0;
            padding: 5px;
            box-shadow: inset 0 0 10px darken(@green, 10%);

            input {
                position: absolute;

                &:checked + label {
                    color: @green;
                    background-color: @white;
                    box-shadow: 0 0 10px 0 darken(@green, 10%);
                }
            }

            label {
                display: inline-block;
                color: fade(@white, 50%);
                text-align: center;
                border-radius: 35px;
                padding: 2px 10px;
                margin: 0;
                transition: all 0.1s ease-in-out;

                &:hover {
                    color: @white;
                    cursor: pointer
                }
            }
        }
    }

    .menu-trigger {
        padding: 22px;
    }

    .menu-wrapper {

        &:after {
            content: '';
            background: url('/theme/img/beeldmerk.png') center no-repeat;
            background-size: 100%;
            width: 700px;
            height: 400px;
        }

        .main-menu {
            height: ~"calc(100vh - 80px)";

            &:before {
                top: -50px;
            }

            li {
                &:hover, &:focus, &:active, &:active:focus, &.active {
                    background: darken(@black, 10%);
                }

                a {
                    font-size: 2.6rem;
                    padding: 2.6rem 0;
                }

                ul {
                    padding: 0;

                    li {

                        a {
                            font-size: 1.8rem;
                            padding: 0 3rem;
                            letter-spacing: 5px;
                        }
                    }
                }
            }
        }
        .sub-menu {

            li {
                display: inline-block;
                border-top: none;

                &:not(:last-child) {
                    border-right: 2px solid @red;
                }

                a {
                    color: fade(@white, 90%);
                    font-size: 1.8rem;
                    padding: 0 3rem;
                    line-height: 1;
                    transition: color 200ms;

                    &:hover, &:focus, &:active, &:active:focus, &.active {
                        background: transparent;
                        color: @red;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .switch {
        padding: 20px 0 0;
        border: none;
    }

    .header-content .dropdown-toggle {
        padding: 5px 18px;
    }

    .dropdown-menu {
        top: 65px;
        left: 10px;
        min-width: 135px;
    }

    .page-intro {

        h1 {
            margin: 0 0 5rem;
        }

        .intro-text {

            > div:before{
                top: -10%;
            }

            .btn-default {

                &:before {
                    top: -40px;
                }
            }

           /* .wb-events-icons-ready &:before {
                content: '\f020';
                font-size: 80%;
                font-family: @icons;
                color: @grey;
                position: absolute;
                top: 10%;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
            }*/
        }
    }

    .featured {

        .image-line {

            li {
                &:after {
                    content: '';
                    width: 120px;
                    height: 120px;
                }
            }
        }
    }

    .news-index {
        img {
            border: 10px solid fade(@black, 5%);
        }

        p {
            margin: 2rem 0 3rem 0;
        }
    }

    .chefkok {

        .item-img {
            position: relative;
            margin-top: 3rem;
            cursor: pointer;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url('/theme/img/mask-playbtn.png') center no-repeat;
                background-size: 100%;
            }
        }

        .item-media:nth-child(-n+4){
            display: block;
        }
    }

    .item {
        margin-bottom: 4rem;
    }

    .item-agenda {
        .meta{
            .vertical-align(absolute);
            right: 0;
        }

        .date, .time {
            text-align: right;
            margin: 0;
        }

        .item-agenda-content {
            margin: 0;

            &:before, &:after {
                top: 50%;
                left: -60px;
                height: 23px;
                width: 23px;
            }

            &:after {
                top: 0;
                left: -50px;
                width: 3px;
                height: 100vh;
            }
        }
    }

    .item-content {
        margin-bottom: 10rem;
    }

    .index-overview, .item-product {

        h3 {
            margin-bottom: 2rem;
        }
    }

    .item-product {

        picture {
            height: 18rem;
        }

        p {
            .line-clamp(15.2rem, 6);
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .item-user {
        p {
            font-size: 2.1rem;
        }
    }

    .item-search {

        &:after {
            content: '\f01a';
            font-family: @icons;
            position: absolute;
            top: 50%;
            right: -50px;
            width: 50px;
            height: 50px;
            font-size: 32px;
            color: @green;
            text-align: center;
            border: 2px solid fade(@grey, 20%);
            border-radius: 50%;
            background: @white;
            opacity: 0;
            transform: translateY(-50%);
            transition: opacity 200ms, right 200ms;
        }

        &:hover {

            &:after {
                content: '\f01a';
                right: -25px;
                opacity: 1;
            }
        }
    }

    .item-overlay {
        padding: 6rem 5rem;

        h2 {
            position: relative;
            font-size: 6rem;
            margin: 2rem 0 3rem;
            line-height: 1;
            color: @blue;

            span {
                display: block;
                color: fade(@blue, 40%);
                font-size: 3rem;
            }
        }

        p {
            margin-bottom: 3rem;
        }
    }

    footer {

        h3 {
            margin-bottom: 3rem;
        }

        .footer-info {
            padding: 7rem 0;

            .footer-naw {

                > div:not(:last-child) {
                    position: relative;

                    &:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        right: 20px;
                        height: 100%;
                        border-right: 1px solid fade(@white, 20%);
                    }
                }
            }
        }
        .footer-sub {
            padding: 0;

            ul {
                &.footer-links {

                    li {
                        display: inline-block;
                        position: relative;
                        padding: 0 1rem 0 0;

                        &:not(:first-child) {
                            padding: 0 1rem;
                        }

                        &:not(:last-child) {

                            &:after {
                                content: '-';
                                color: @white;
                                position: absolute;
                                right: -2px;
                            }
                        }
                    }
                }

                li a {
                    padding: 0 1rem;
                }
            }
        }

        .form-inline .form-control {
            width: 22rem;
        }
    }

    .producten-show,  .en-producten-show, .partners-show, .en-partners-show{

        .standaard {

            .product-logo, .partner-logo {
                top: -50px;
                padding: 10px;
            }
        }
    }

    .product-content {
        position: relative;
        margin-top: 6rem;

        &:not(:last-child){

            .product-text {
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }

        .product-image, .product-text {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &:nth-child(even) {
            .product-image {
                right: 0;
            }
        }
        &:nth-child(odd) {
            > div:nth-child(2) {
                right: 0;
            }
        }
    }

    .special-home-particulier{

        footer{

            h3{
                margin-bottom: 0;
            }

            .form-newsletter{
                margin-top: 16px;
            }
        }
    }
}

@media (min-width: 992px) {

    html {
        font-size: 9px;
    }

    h1, .h1 {
        font-size: 9rem;
    }

    .btn-default {

        &.btn-classic, &.btn-select, &.btn-gold {
            padding: 0 10px 0 40px;

            &:after {
                content: '';
                top: 23px;
                left: 5px;
                width: 30px;
            }
        }
    }

    .btn-default, .btn-primary{
        padding: 0 45px 0 30px;
    }

    .form-control{
        padding: 6px 20px;
    }

    .overlay-popup {
        min-height: 40vh;
        width: 355px;
    }

    header {
        height: 100px;

    }

    .header-content {

        > div {

            &:not(:last-child) {
                padding: 24px 7px 0;
            }
            &.primary-class {

                .btn-primary {
                    line-height: 2.2;
                }
            }

            &.search{
                width: 500px;
            }
        }
    }

    main {
        margin-top: 100px;
    }

    .menu-trigger {
        padding: 32px;
    }

    .menu-wrapper {
        .main-menu {
            height: ~"calc(100vh - 100px)";
        }
    }

    .switch {
        padding: 30px 0 0;

        > div:first-child{
            display: inline-block;
        }
    }

    .dropdown-menu {
        top: 80px;
        left: 30px;
        min-width: 160px;
    }

    .page-intro {

        h1 {
            margin: 0 0 5rem;
        }

        .intro {
            height: 600px;
        }

        .intro-text {
            height: 600px;

            &:before {
                top: 20%;
            }
        }
    }

    .item-video {
        height: 600px;

        .item-meta {
            padding: 0 100px;

            .lead {
                color: @white;

                &:hover, &:focus, &:active, &:active:focus {
                    text-decoration: none;
                }
            }
        }
    }

    .index-overview {

        > div {
            height: 90vh;
            overflow: hidden;

            > div {

                &:first-child {
                    height: 100%;
                    overflow: hidden;

                    picture img {
                        position: absolute;
                        top: 0;
                        left: 50%;
                        right: 0;
                        height: 100%;
                        display: block;
                        width: auto;
                        max-width: none;
                        transform: translateX(-50%);
                    }
                }

                &:last-child {
                    height: 100%;
                    overflow: auto;
                    padding: 0 40px;
                }
            }
        }
    }

    .item-user {
        img {
            border: 15px solid @white;
        }
    }

    .filter li .btn {
        font-size: 1.8rem;
        line-height: 2.5;
    }

    footer {

        .footer-content {
            padding: 7rem 0;
        }
        .footer-sub {
            padding: 0;

            ul li a {
                padding: 0 1.5rem;
            }
        }
    }

    .special-home-particulier{

        footer{
            .form-newsletter{
                margin-top: 10px;
            }
        }
    }

    .chefkok .item-media:nth-child(-n+6){
        display: block;
    }
}

@media (min-width: 1200px) {

    html {
        font-size: 10px;
    }

    section {
        padding: 10rem 0;
    }

    .parallax-wrap{
        height: 400px;
    }

    .btn-default {

        &.btn-classic, &.btn-select, &.btn-gold {
            padding: 0 20px 0 55px;

            &:after {
                content: '';
                left: 10px;
                width: 36px;
            }
        }
    }

    .btn-link, .btn-prev {
        font-size: 2.1rem;
    }

    .header-content {

        > div {

            &.primary-class {

                .btn-primary {
                    line-height: 2.1;
                    width: 225px;
                }
            }
        }
    }

    .filter li .btn {
        font-size: 2.1rem;
        line-height: 2.1;
    }

    .page-intro {

        .intro-text {

            > div {
                left: 15%;
                right: 15%;
            }
        }
    }

    .featured .featured-information {

        .btn-default {
            padding: 0 45px 0 30px;

            &:after{
                right: 25px;
            }
        }
    }

    footer {

        .footer-info {

            .footer-naw {

                > div:not(:last-child) {

                    &:after {
                        right: 50px;
                    }
                }
            }
        }
        .footer-content .form-inline .form-control {
            width: 30rem;
        }
    }
}

@media (min-width: 1400px) {

    h2, .h2 {
        font-size: 6rem;
    }

    .modal-dialog{
        width: 50%;
    }

    .page-intro {

        .intro-text {
            height: 860px;
        }
    }

    .item-video {
        height: 860px;
    }

    .item-product {

        p {
            padding: 0 3rem;
        }
    }

    .index-overview {

        .filter {

            li {
                margin: 0 1rem;
            }
        }
    }

    .header-content {

        > div {

            &:not(:last-child) {
                padding: 24px 15px 0;
            }
        }
    }
}