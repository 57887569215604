@import url(mixins.less);
@import url(cols.less);

@color_facebook: #3b5998;
@color_twitter: #00aced;
@color_linkedin: #007bb6;
@color_googleplus: #dd4b39;
@color_pinterest: #cb2027;
@color_youtube: #bb0000;
@color_instagram: #517fa4;

*, input[type=file], input[type=checkbox], input[type=radio] {
    &:focus {
        outline: 0;
    }
}

// Grid
.row.no-gutters {
    margin-right: 0;
    margin-left: 0;

    > [class^="col-"],
    > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.row-xs-eq-height {
    display: flex;
}

body {
    .font-smoothing();
}

.alert-env {
    background: #000;
    border-radius: 0;
    margin-bottom: 0;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: normal;
    color: @white;
    text-align: center;
}

@media (min-width: 768px) {
    .row-sm-eq-height {
        display: flex;
    }
}

@media (min-width: 992px) {
    .row-md-eq-height {
        display: flex;
    }
}

@media (min-width: 1200px) {
    .row-lg-eq-height {
        display: flex;
    }
}

@media (min-width: 1400px) {
    .row-xl-eq-height {
        display: flex;
    }

    .col-xl;
}

@media (max-width: 480px) {
    .col-xxs;
}

